<template>
  <div class="mt-2 mb-4 service-info">
    <v-row
      v-for="(item, index) in filteredFields"
      :key="`form-site-info-type-coating-${index}`"
      :align="item.type === 'radio' ? 'center' : 'start'"
    >
      <v-col cols="2" class="form-header text-right">
        {{ item.label }}
      </v-col>
      <v-col cols="9" :class="item.type === 'textarea' ? 'flex-grow-1' : ''">
        <v-row class="ma-0" align="center">
          <template v-if="item.type === 'radio'">
            <validation-provider v-slot="{ errors }" :name="item.key" rules="">
              <v-radio-group
                v-model="data[item.key]"
                row
                :error-messages="errors"
                :error="errors.length !== 0"
                hide-details
              >
                <template v-for="(subItem, subIndex) in item.values">
                  <v-radio
                    :key="`form-site-info-${item.key}-${subIndex}`"
                    :label="subItem"
                    :value="subIndex"
                  >
                  </v-radio>
                  <span class="unselect" v-if="subIndex === item.values.length - 1" :key="subIndex" @click="data[item.key] = null">
                  選択解除
                  </span>
                  <template
                    v-if="
                      item.extra &&
                        subIndex == item.extra.condition.value &&
                        !evaluateCondition(item.key, item.extra.condition)
                    "
                  >
                    <v-row :key="`${subIndex}`" align="center">
                      <v-col v-if="item.extra" cols="auto">
                        {{ item.extra.label }}
                      </v-col>
                      <v-col v-if="item.extra" cols="auto">
                        <validation-provider
                          v-slot="{ errors }"
                          :name="item.key"
                        >
                          <v-text-field
                            v-model="data[item.extra.key]"
                            class="form-text custom-width"
                            dense
                            :error-messages="errors"
                            :error="errors.length !== 0"
                            :hide-details="errors.length === 0"
                            outlined
                            :disabled="
                              evaluateCondition(item.key, item.extra.condition)
                            "
                          ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </template>
                </template>
              </v-radio-group>
            </validation-provider>
          </template>

          <template v-else-if="item.type === 'checkbox'">
            <v-row align="center">
              <v-radio-group row hide-details class="mt-0">
                <template v-for="(subItem, subIndex) in item.values">
                  <InputCheckbox
                    :key="`checkbox-${item.key}-${subIndex}`"
                    :subItem="subItem"
                    :dataModel.sync="data[item.key]"
                  />
                  <template
                    v-if="
                      subItem.extra_type &&
                        getIndexByItem(data[item.key], subItem) != -1
                    "
                  >
                    <v-text-field
                      :key="`textfield-${item.key}-${subIndex}`"
                      v-if="subItem.extra_type"
                      v-model="
                        data[item.key][getIndexByItem(data[item.key], subItem)]
                          .text_value
                      "
                      dense
                      outlined
                      class="form-text ml-2 mr-2 mb-2"
                      hide-details
                      height="10"
                      @input="changeInput($event, data[item.key], subItem)"
                    >
                    </v-text-field>
                  </template>
                </template>
              </v-radio-group>
            </v-row>
          </template>

          <template v-else-if="item.type === 'textarea'">
            <v-textarea
              class="form-text"
              outlined
              hide-details
              :placeholder="item.values"
            >
            </v-textarea>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InputCheckbox from './Input/InputCheckbox'
export default {
  name: 'ServiceInfo',
  components: { InputCheckbox },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    filteredFields() {
      return this.fields.filter(
        form =>
          form.service_type === -1 ||
          form.service_type === this.data.service_type_id
      )
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'elevator',
          label: 'エレベータ',
          type: 'radio',
          values: ['あり', 'なし'],
          service_type: 1
        },
        {
          key: 'site_age',
          label: '状況',
          type: 'radio',
          values: ['新築', '中古'],
          service_type: 0
        },
        {
          key: 'residents',
          label: '入居者',
          type: 'radio',
          values: ['あり', 'なし'],
          service_type: 0
        },
        {
          key: 'furnitures',
          label: '家具類',
          type: 'radio',
          values: ['あり', 'なし'],
          service_type: 0
        },
        {
          key: 'parking',
          label: '駐車場',
          type: 'radio',
          values: ['敷地内', 'コインパーキング', '近隣'],
          extra: {
            key: 'parking_text',
            label: 'マンション駐車場No.',
            type: 'text',
            condition: {
              operator: '===',
              value: 0
            }
          },
          service_type: -1
        },
        {
          key: 'key',
          label: '鍵',
          type: 'checkbox',
          values: [
            {
              text: '立ち合い',
              value: '0'
            },
            {
              text: '量水器',
              value: '1'
            },
            {
              id: 1,
              extra_type: 'with_text',
              text: 'キーバンカー',
              value: '2'
            },
            {
              id: 2,
              extra_type: 'with_text',
              text: 'その他対応',
              value: '3'
            }
          ],
          service_type: -1
        },
        {
          key: 'lightening_braker',
          label: 'プレーカー',
          type: 'radio',
          values: ['入', '切'],
          service_type: 0
        },
        {
          key: 'outlet',
          label: '電源',
          type: 'radio',
          values: ['本設', '仮設'],
          service_type: 0
        },
        {
          key: 'electrical_issues',
          label: '照明電気異常',
          type: 'radio',
          values: ['あり', 'なし'],
          extra: {
            key: 'electrical_issues_text',
            label: '詳細',
            type: 'text',
            condition: {
              operator: '===',
              value: 0
            }
          },
          service_type: 0
        },
        {
          key: 'shutter',
          label: 'シャッター',
          type: 'radio',
          values: ['あり', 'なし'],
          service_type: 0
        },
        {
          key: 'curtain',
          label: 'カーテン',
          type: 'radio',
          values: ['あり', 'なし'],
          unselect: true,
          unselectText: '選択解除',
          service_type: 0
        },
        {
          key: 'air_conditioner',
          label: 'エアコン',
          type: 'radio',
          values: ['あり', '使用', '不使用', 'なし'],
          service_type: 0
        }
      ]
    }
  },
  created() {
    this.fillTextValueFields()
  },
  methods: {
    fillTextValueFields() {
      this.fields.forEach(field => {
        let data = this.data[field.key]
        if (data && field.type === 'checkbox' && data?.length) {
          let subItems = field?.values.filter(f =>
            data.some(d => d.id === f.id)
          )
          subItems.forEach(subItem => {
            subItem.text_value = data.find(d => d.id === subItem.id).text_value
          })
        }
      })
    },
    evaluateCondition(key, condition) {
      return !eval(`this.data.${key} ${condition.operator} ${condition.value}`)
    },
    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },
    changeInput(e, data, item) {
      data[this.getIndexByItem(data, item)].text_value = e
    },
  }
}
</script>

<style lang="scss" scoped>
.form {
  &-header {
    color: #393a00;
  }
  &-text {
    box-shadow: unset;
    ::v-deep {
      .v-input__slot {
        background: #ffffff;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }
  }
}
.unselect {
margin-top: 4px;
cursor: pointer;
font-size: 90%;
color: #cf2600;
}
.service-info {
  ::v-deep {
    .v-input--selection-controls {
      margin-top: 0;
    }
  }
  .custom-width {
    max-width: 12rem;
  }
}
</style>
