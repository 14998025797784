<template>
  <v-container v-if="claimPagination.records_total > 0">
    <v-row>
      <v-col cols="12">
        <Table
          :loading="customerClaimLoading"
          :headers="headers"
          :items="claims"
          :total-records="
            claimPagination && claimPagination.records_total
              ? claimPagination.records_total
              : 0
          "
          :number-of-pages="
            claimPagination && claimPagination.total_pages
              ? claimPagination.total_pages
              : 1
          "
          :footer="false"
          class="font-weight-medium mb-4"
          @pagination:update="updateCustomerClaims"
        >
          <template v-slot:item.srno="{ index }">
            {{ indexStart + index + 1 }}
          </template>

          <template v-slot:item.content="{ item }">
            <v-container>
              <v-row>
                <v-col cols="2" class="form-header text-right">Task ID: </v-col>
                <v-col cols="10" class="text-center">
                  <v-row>
                    <v-col cols="1" class="task-id">
                      <router-link
                        :to="{ name: 'TaskView', params: { id: item.task.id } }"
                        target="_blank"
                      >
                        {{ item.task.id }}
                      </router-link>
                    </v-col>
                    <v-col cols="4">{{
                      getDateFormat(item.task.date, 'ja')
                    }}</v-col>
                    <v-col cols="5">
                      <div style="margin-bottom:10px" v-if="item.created_by">
                        投稿者: {{ item.created_by.last_name }} 投稿日時:
                        {{ getDateFormat(item.created_at) }}
                      </div>
                      <div v-if="item.updated_by">
                        更新者: {{ item.updated_by.last_name }} 更新日時:
                        {{ getDateFormat(item.updated_at) }}
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2" class="form-header text-right">メンバー:</v-col>
                <v-col cols="10">
                  <v-row>
                    <v-col cols="auto">
                      <span class="font-weight-bold" v-if="item.leader"
                        >{{
                          item.leader.last_name + ' ' + item.leader.first_name
                        }},</span
                      >
                      <!--<span v-for="(member, index) in item.task.task_members" :key="index">
                        {{ member.last_name+" "+member.first_name}},
                      </span>-->
                      {{ taskMembers(item) }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="2" class="form-header text-right"> 分類: </v-col>
                <v-col cols="10" class="claim-categories">
                  <v-chip
                    color="#8C5F5F"
                    text-color="white"
                    v-for="(category, index) in item.categories"
                    :key="index"
                    label
                    small
                    class="mr-3 mb-2"
                  >
                    {{ category.name }}
                  </v-chip>
                </v-col>

                <v-col cols="2" class="form-header text-right"
                  >クレーム内容:
                </v-col>
                <v-col cols="10" class="claim-des">{{
                  item.description
                }}</v-col>

                <v-col cols="2" class="form-header text-right"> 対応: </v-col>
                <v-col cols="10" class="claim-des">{{ item.response }}</v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  物件に関連するクレーム一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3">
                  全 ({{ customerClaimsCount }}) 件 あります。
                </v-col>
              </v-row>
            </div>
          </template>
          -->
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'
import Table from '@/components/admin/partials/Table/Table'
import UpdateList from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateList'
export default {
  name: 'Claim',
  props: {
    customer: {
      type: Object,
      required: true
    },
    projectId: {
      type: [Number, String]
    }
  },
  components: { Table },
  data() {
    return {
      claimItem: {},
      page: 1,
      pageCount: 5,
      headers: [
        { text: '#', align: 'center', value: 'srno', width: 50 },
        {
          text: '',
          value: 'content',
          width: 500,
          sortable: false
        },
        {
          text: '',
          align: 'center',
          value: 'actions',
          width: 50,
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'allCustomerclaims',
      'allClaimCategories',
      'customerClaimPagination',
      'customerClaimLoading',
      'customerClaimsCount'
    ]),
    claims() {
      return this.allCustomerclaims
    },
    claimPagination() {
      return this.customerClaimPagination
    },
    claimCategories() {
      return this.allClaimCategories
    },
    site_id() {
      return this.$route.params.site_id
    },
    indexStart() {
      return this.customerClaimPagination.records_from - 1
    }
  },
  mounted() {
    this.getDataFromApi()
  },
  methods: {
    view(taskid) {
      this.$router.push('/task/' + taskid)
    },
    getDataFromApi() {
      this.$store.dispatch('CLAIM_CATEGORY_GET_ALL').then(() => {
        this.loading = false
      })
    },
    taskMembers(item) {
      let team_members = ''
      let leaderId = 0

      if (item.leader) {
        leaderId = item.leader.id
      }
      if (item.task.task_members) {
        item.task.task_members.map(taskMembers => {
          if (leaderId != taskMembers.id) {
            team_members +=
              taskMembers.last_name + ' ' + taskMembers.first_name + ', '
          }
        })
      }
      return team_members
    },
    updateCustomerClaims(page) {
      this.page = page
      let param = {
        customer_id: this.$route.params?.customer_id,
        site_id: this.$route.params?.site_id,
        project_id: this.projectId,
        paginate: 10,
        page: page
      }
      this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param)
    },
    getDateFormat(date, ln) {
      if (ln == 'ja') {
        return dayjs(date).format(`YYYY年MM月DD日`)
      } else {
        return dayjs(date).format(`YYYY/MM/DD H:mm`)
      }
    },
  }
}
</script>

<style lang="scss" src="./Claim.scss" scoped></style>
