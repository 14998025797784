<template>
  <div>
    <div v-if="project.service_type_id == 0" class="info-box mb-6 pa-8">
      <v-row>
        <v-col cols="3" class="info-box-headline text-heading-2">
          <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>作業情報
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-row dense>
            <v-col cols="12" class="txt-headline">
              <span v-for="(genre, index) in project.svc_coating.coating_genre" :key="`${index}-${genre}`">
                <span>
                  {{
                      coating_genres[genre]
                  }}
                  <span v-if="index !== project.svc_coating.coating_genre.length - 1"> , </span>
                </span>
              </span>

              <span class="ml-4">{{
                  svc_coating.coating_area ? svc_coating.coating_area : 0
              }}
                ㎡</span>
            </v-col>
            <v-col cols="12" class="txt-para">
              {{
                  svc_coating.coating_genre === 3
                    ? svc_coating.coating_range2_text
                    : svc_coating.coating_range1_text
              }}
            </v-col>

            <v-col cols="12" class="mt-5">
              <v-row dense>
                <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">床材の色</v-col>
                <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3 d-flex">
                  <template v-if="svc_coating.floor_materials.length !== 0">
                    <div :key="`coating-color-${index}`" class="d-flex align-center" v-for="(floorMaterial,
                    index) in svc_coating.floor_materials">
                      <v-avatar class="mr-4" tile min-height="65" min-width="100" style="border-radius: 5% !important;"
                        :color="
                          floorMaterial.type === 0
                            ? floorMaterial.color_code
                            : ''
                        ">
                        <v-img min-height="65" min-width="100" v-if="floorMaterial.type === 1"
                          :src="floorMaterial.image_url" />
                      </v-avatar>
                      <span class="mr-4">
                        {{ floorMaterial.name }}
                        <template v-if="floorMaterial.pivot.custom_value">
                          ( {{ floorMaterial.pivot.custom_value }} )
                        </template>
                      </span>
                    </div>
                  </template>
                  <span v-else v-for="(floor, index) in svc_coating.floor_color" cols="auto"
                    :key="`coating-color-${index}`" class="d-flex align-center mr-3">
                    <span :style="{ backgroundColor: floor.color }" class="dot mr-1"></span>
                    <!-- <v-icon size="24" :color="floor.color">$dot</v-icon> -->
                    {{ floor.text }}
                    <template v-if="floor.id">
                      ({{ floor.text_value }})
                    </template>
                  </span>
                </v-col>
                <template v-if="floor_genres[svc_coating.floor_genre]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">床材種類</v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3">
                    {{
                        floor_genres[svc_coating.floor_genre] + ' (' + svc_coating.floor_genre_memo + ')'
                    }}
                  </v-col>
                </template>
                <template v-if="tough_sealers[svc_coating.tough_sealer]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3">タフシーラー</v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3">
                    {{
                        tough_sealers[svc_coating.tough_sealer]
                    }}
                  </v-col>
                </template>
                <template v-if="removal_works[svc_coating.removal_work]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">剥離作業</v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3">
                    {{
                        removal_works[svc_coating.removal_work]
                    }} <span v-if="svc_coating.removal_work === 0">( {{ project.svc_coating.memo }} )</span>
                  </v-col>
                </template>
                <template v-if="micro_mops[svc_coating.micro_mop]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">マイクロモップ</v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3">
                    {{
                        micro_mops[svc_coating.micro_mop]
                    }}
                  </v-col>
                </template>
                <template v-if="premium_cleaners[svc_coating.premium_cleaner]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">プレミアム専用クリーナー
                  </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3">
                    {{
                        premium_cleaners[svc_coating.premium_cleaner]
                    }}
                  </v-col>
                </template>
                <template v-if="counter_table[svc_coating.counter_table]">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3 pb-3">多目的カウンター </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 pb-3">
                    {{
                        counter_table[svc_coating.counter_table]
                    }}
                  </v-col>
                </template>
                <template v-if="project.svc_coating.assigned_partner">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3">外部発注
                  </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3">
                    {{ project.svc_coating.assigned_partner }}
                  </v-col>
                </template>
                <template v-if="project.svc_coating.partner_workrange && project.svc_coating.assigned_partner">
                  <v-col cols="4" class="info-box-work-information-content-label text-heading-3">発注範囲
                  </v-col>
                  <v-col cols="8" class="info-box-work-information-content-content text-heading-3 white-space-preline">
                    {{ project.svc_coating.partner_workrange }}
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div v-if="project.service_type_id == 0 && svc_coating.premium_guard.length" class="info-box mb-6 pa-8">
      <v-row>
        <v-col cols="3" class="info-box-headline text-heading-2">
          <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>プレミアムガード
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col v-for="item in svc_coating.premium_guard" :key="item.text"
              class="info-box-work-information-headline sub">
              {{ item.text }}
              <template v-if="item.text_value">
                ({{ item.text_value }})
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div v-if="project.service_type_id == 0 && svc_coating.campaign" class="info-box mb-6 pa-8">
      <v-row>
        <v-col cols="3" class="info-box-headline text-heading-2">
          <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>キャンペーンサービス
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-row>
            <v-col cols="12" class="info-box-work-information-headline sub">
              {{ svc_coating.campaign }}
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <div v-if="project.service_type_id == 0 && svc_coating.coating_option.length" class="info-box mb-6 pa-8">
      <v-row>
        <v-col cols="3" class="info-box-headline text-heading-2">
          <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>サービス施工
        </v-col>
        <v-col cols="9">
          <v-row>
            <v-col v-for="item in svc_coating.coating_option" :key="item.text"
              class="info-box-work-information-headline sub">
              {{ item.text }}
              <template v-if="item.text_value">
                ({{ item.text_value }})
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>



    <template v-if="project.service_type_id == 1">
      <div class="info-box mb-6 pa-8" v-if="svc_floor.prep_text
      || svc_floor.detergent_text
      || svc_floor.detergent_method_text
      || svc_floor.cleaning_method_text
      || svc_floor.polisher_text
      || svc_floor.cleaning_machine_text
      || svc_floor.pat.length
      || svc_floor.certain_place_method_text
      || svc_floor.certain_place_explanation
      || svc_floor.waxing_place_text
      || svc_floor.waxing_product_text
      || svc_floor.waxing_rule">
        <v-row no-gutters>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>共用部洗浄 床洗浄ワックス
          </v-col>
          <v-col cols="9" class="flex-grow-1">
            <v-row no-gutters>
              <template v-if="svc_floor.prep_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">清掃前準備</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.prep_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.detergent_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用洗剤</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.detergent_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.detergent_method_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">洗剤塗布方法</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.detergent_method_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.cleaning_method_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">基本洗浄方法</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.cleaning_method_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.polisher_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用ポリッシャー</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.polisher_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.cleaning_machine_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用自洗機</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.cleaning_machine_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.pat.length">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用パット</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3 d-flex">
                  <span v-for="(pat, index) in svc_floor.pat" cols="auto" :key="`coating-color-${index}`"
                    class="d-flex align-center mr-3">
                    <!-- <v-icon size="24" :color="pat.color">$dot</v-icon> -->
                    <span :style="{ backgroundColor: pat.color }" class="dot mr-1"></span>
                    {{ pat.text }}
                  </span>
                </v-col>
              </template>
              <template v-if="svc_floor.certain_place_method_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">場所指定洗浄方法</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.certain_place_method_text }}
                </v-col>
              </template>
              <v-col cols="3" class="info-box-work-information-content-label text-heading-3">指定場所</v-col>
              <v-col cols="9" class="info-box-work-information-content-content text-heading-3 cust-line-height"
                style="white-space: pre-line">
                {{
                    svc_floor.certain_place_explanation
                      ? svc_floor.certain_place_explanation
                      : '-'
                }}
              </v-col>
            </v-row>

            <v-divider class="my-8"></v-divider>

            <v-row no-gutters>
              <!-- <template v-if="svc_floor.waxing_direction_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">ワックス塗布階数</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.waxing_direction_text }}
                </v-col>
              </template> -->
              <template v-if="svc_floor.waxing_place_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">範囲指定</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3" v-html="svc_floor.waxing_place_text">
                  <!-- {{  }} -->
                </v-col>
              </template>
              <template v-if="svc_floor.waxing_product_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用ワックス</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_floor.waxing_product_text }}
                </v-col>
              </template>
              <template v-if="svc_floor.waxing_rule">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">注意事項</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 cust-line-height"
                  style="white-space: pre-line">
                  {{ svc_floor.waxing_rule }}
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="info-box mb-6 pa-8" v-if="svc_window.stepladder_text ||
        svc_window.extension_pole_text ||
        svc_window.safety_helmet_text ||
        svc_window.safety_belt_text ||
        svc_window.outside_text ||
        svc_window.inside_text ||
        svc_window.certain_window_method_text ||
        svc_window.certain_window_explanation ||
        svc_window.certain_window_rule
      ">
        <v-row no-gutters>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            窓ガラス清掃
          </v-col>
          <v-col cols="9" class="flex-grow-1">
            <v-row no-gutters>
              <template v-if="svc_window.stepladder_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用脚立</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.stepladder_text }}
                </v-col>
              </template>
              <template v-if="svc_window.extension_pole_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">延長ポール</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.extension_pole_text }}
                </v-col>
              </template>
              <template v-if="svc_window.safety_helmet_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">ヘルメット</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.safety_helmet_text }}
                </v-col>
              </template>
              <template v-if="svc_window.safety_belt_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">安全帯</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.safety_belt_text }}
                </v-col>
              </template>
              <template v-if="svc_window.outside_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">外面</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.outside_text }}
                </v-col>
              </template>
              <template v-if="svc_window.inside_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">内面</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.inside_text }}
                </v-col>
              </template>
              <template v-if="svc_window.certain_window_method_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">場所指定特記事項</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_window.certain_window_method_text }}
                </v-col>
              </template>
              <template v-if="svc_window.certain_window_explanation">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">指定場所</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3 cust-line-height"
                  style="white-space: pre-line">
                  {{ svc_window.certain_window_explanation }}
                </v-col>
              </template>
              <template v-if="svc_window.certain_window_rule">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">注意事項</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 cust-line-height"
                  style="white-space: pre-line">
                  {{ svc_window.certain_window_rule }}
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="info-box mb-6 pa-8" v-if="svc_carpet.prep_text
      || svc_carpet.pre_task_text
      || svc_carpet.washing_text
      || svc_carpet.machine_text
      || svc_carpet.stain_removal_text
      || svc_carpet.wastewater_text">
        <v-row no-gutters>
          <v-col cols="3" class="info-box-headline text-heading-2">
            <v-icon class="mr-1" color="#4F55A7" size="6">$dot</v-icon>
            カーペット洗浄
          </v-col>
          <v-col cols="9" class="flex-grow-1">
            <v-row no-gutters>
              <template v-if="svc_carpet.prep_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">作業前工程</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_carpet.prep_text }}
                </v-col>
              </template>
              <template v-if="svc_carpet.pre_task_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">前処理作業</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_carpet.pre_task_text }}
                </v-col>
              </template>
              <template v-if="svc_carpet.washing_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">洗浄作業</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_carpet.washing_text }}
                </v-col>
              </template>
              <template v-if="svc_carpet.machine_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">使用バルチャー</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_carpet.machine_text }}
                </v-col>
              </template>
              <template v-if="svc_carpet.stain_removal_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3 pb-3">染み抜き</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3 pb-3">
                  {{ svc_carpet.stain_removal_text }}
                </v-col>
              </template>
              <template v-if="svc_carpet.wastewater_text">
                <v-col cols="3" class="info-box-work-information-content-label text-heading-3">汚水回収</v-col>
                <v-col cols="9" class="info-box-work-information-content-content text-heading-3">
                  {{ svc_carpet.wastewater_text }}
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </template>

    <div v-if="project.service_type_id > 1" class="info-box mb-6 pa-8">
      <v-row align="baseline">
        <v-col cols="2" class="info-box-work-information-content-label text-center">作業内容1</v-col>
        <v-col cols="10" class="info-box-work-information-content-content text-pre-wrap">
          {{ svc_other.task_description_1 }}
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="2" class="info-box-work-information-content-label text-center">作業内容2</v-col>
        <v-col cols="10" class="info-box-work-information-content-content text-pre-wrap">
          {{ svc_other.task_description_2 }}
        </v-col>
      </v-row>
      <v-row align="baseline">
        <v-col cols="2" class="info-box-work-information-content-label text-center">作業内容3</v-col>
        <v-col cols="10" class="info-box-work-information-content-content text-pre-wrap">
          {{ svc_other.task_description_3 }}
        </v-col>
      </v-row>
    </div>

    <div v-if="project.service_type_id == 0" class="mb-6">
      <AfterService :projectId="projectId" :customer="customer"></AfterService>
    </div>
    <div v-else-if="project.service_type_id != 0">
      <Claim :projectId="projectId" :customer="customer" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'
import Claim from '@/components/admin/partials/Customer/View/Basic/Informations/Claim'
import AfterService from '@/components/admin/partials/Customer/View/Basic/Informations/AfterService'
import DialogAddCoatingAfter from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/AddCoatingAfter.vue'
import DialogEditCoatingAfter from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/EditCoatingAfter.vue'
export default {
  name: 'WorkInformation',
  components: {
    AfterService,
    Claim
    // DialogEditCoatingAfter
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    selectedService: {
      type: Number
    },
    projectId: {
      required: false,
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['allUsersList']),
    siteId() {
      return parseInt(this.$route.params?.site_id)
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id === this.siteId)
    },
    project() {
      return this.selectedSite?.projects?.find(
        project => project.id === this.selectedService
      )
    },
    svc_coating() {
      const coating = this.project.svc_coating
      coating.coating_range1_text = this.showText(coating.coating_range1)
      coating.coating_range2_text = this.showText(coating.coating_range2)
      // coating.floor_color_text = this.showText(coating.floor_color)

      return coating
    },
    svc_floor() {
      const floor = this.project.svc_floor
      floor.prep_text = this.showText(floor.prep)
      floor.detergent_text = this.showText(floor.detergent)
      floor.detergent_method_text = this.showText(floor.detergent_method)
      floor.cleaning_method_text = this.showText(floor.cleaning_method)
      floor.polisher_text = this.showText(floor.polisher)
      floor.cleaning_machine_text = this.showText(floor.cleaning_machine)
      floor.pat_text = this.showText(floor.pat)
      floor.certain_place_method_text = this.showText(
        floor.certain_place_method
      )
      // floor.waxing_direction_text = this.showText(
      //   Object.values(Object.values(floor.waxing_direction))
      // )
      floor.waxing_place_text = '' //this.showText(Object.values(floor.waxing_place))

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_all)) {
        floor.waxing_place_text += '全体：'
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction) {
          floor.waxing_place_text += floor.waxing_direction?.waxing_place_all_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_all) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_all_direction && floor.waxing_place.waxing_place_all) {
            floor.waxing_place_text += ' (' + floor.waxing_place?.waxing_place_all + ")"
          } else {
            floor.waxing_place_text += floor.waxing_place?.waxing_place_all
          }
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_1)) {
        floor.waxing_place_text += '</br>'
        floor.waxing_place_text += '部分1: '
        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_1_direction) {
          floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_1_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_1) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_1) {
            floor.waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_1 + ")"
          } else {
            floor.waxing_place_text +=floor.waxing_place?.waxing_place_part_1
          }        
        }
      } 

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_2)) {
        floor.waxing_place_text += '</br>'
        floor.waxing_place_text += '部分2: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction) {
          floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_2_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_2) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_2_direction && floor.waxing_place.waxing_place_part_2) {
            floor.waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_2 + ")"
          } else {
            floor.waxing_place_text += floor.waxing_place?.waxing_place_part_2
          }        
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_3)) {
        floor.waxing_place_text += '</br>'
        floor.waxing_place_text += '部分3: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction) {
          floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_3_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_3) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_3_direction && floor.waxing_place.waxing_place_part_3) {
            floor.waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_3 + ")"
          } else {
            floor.waxing_place_text += floor.waxing_place?.waxing_place_part_3
          }        
        }
      }

      if((floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) || (floor.waxing_place && floor.waxing_place?.waxing_place_part_4)) {
        floor.waxing_place_text += '</br>'
        floor.waxing_place_text += '部分4: '

        if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction) {
          floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_4_direction?.text
        }
        if(floor.waxing_place && floor.waxing_place?.waxing_place_part_4) {
          if(floor.waxing_direction && floor.waxing_direction?.waxing_place_part_4_direction && floor.waxing_place.waxing_place_part_4) {
            floor.waxing_place_text += ' (' + floor.waxing_place?.waxing_place_part_4 + ")"
          } else {
            floor.waxing_place_text += floor.waxing_place?.waxing_place_part_4
          }        
        }
      }

      // Object.values(floor.waxing_place).forEach(function (place, key) {
      //   if (key == 0) {
      //     // if(floor.waxing_direction?.waxing_place_all_direction?.text) 
      //     floor.waxing_place_text += '全体: ' + (floor.waxing_direction?.waxing_place_all_direction?.text ? floor.waxing_direction?.waxing_place_all_direction?.text + ' (' + place + '),<br />' : ' ' + place != 'null' ? place : '' + ',<br />')
      //   }
      //   if (key == 1) {
      //     floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_1_direction?.text ? ' ' + floor.waxing_direction?.waxing_place_part_1_direction?.text + ' (' + place + '),' : ' ' + place + ','
      //   }
      //   if (key == 2) {
      //     floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_2_direction?.text ? ' ' + floor.waxing_direction?.waxing_place_part_2_direction?.text + ' (' + place + '),' : ' ' + place + ','
      //   }
      //   if (key == 3) {
      //     floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_3_direction?.text ? ' ' + floor.waxing_direction?.waxing_place_part_3_direction?.text + ' (' + place + '),' : ' ' + place + ','
      //   }
      //   if (key == 4) {
      //     floor.waxing_place_text += floor.waxing_direction?.waxing_place_part_4_direction?.text ? ' ' + floor.waxing_direction?.waxing_place_part_4_direction?.text + ' (' + place + ')' : ' ' + place
      //   }
      // });
      floor.waxing_product_text = this.showText(floor.waxing_product)

      return floor
    },
    svc_window() {
      const window = this.project.svc_window
      window.stepladder_text = this.showText(window.stepladder)
      window.extension_pole_text = this.showText(window.extension_pole)
      window.safety_helmet_text = this.showText(window.safety_helmet)
      window.safety_belt_text = this.showText(window.safety_belt)
      window.outside_text = window.outside_radio == 1 ? 'なし' : 'あり(' + this.showText(window.outside) + ')'
      window.inside_text = this.showText(window.inside)
      window.certain_window_method_text = this.showText(
        window.certain_window_method
      )

      return window
    },
    svc_carpet() {
      const carpet = this.project.svc_carpet
      carpet.prep_text = this.showText(carpet.prep)
      carpet.pre_task_text = this.showText(carpet.pre_task)
      carpet.washing_text = this.showText(carpet.washing)
      carpet.machine_text = this.showText(carpet.machine)
      carpet.stain_removal_text = this.showText(carpet.stain_removal)
      carpet.wastewater_text = this.showText(carpet.wastewater)

      return carpet
    },
    svc_other() {
      const other = this.project.svc_other

      return other
    }
  },
  methods: {
    showUser(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              return `${c?.last_name} ${c?.last_name}`
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    },

    showText(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    },

    showTextReason(array) {
      if (array) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.name ? c.name : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    }
  },
  data() {
    return {
      coating_genres: [
        'プレミアムコートEX',
        'プレミアムコートEXプラス',
        'プレミアムコートUVプラス',
        'シリコンコーティング',
        '水性コーティング',
        'プレミアムガード'
      ],
      floor_genres: [
        '3溝突板',
        '2溝突板',
        '1溝タイプ（フラット）',
        '1溝タイプ（ハピアタイプ）',
        '1溝タイプ（木目強調タイプ）',
        '化学床'
      ],
      removal_works: ['あり', 'なし'],
      micro_mops: ['あり', 'なし'],
      premium_cleaners: ['あり', 'なし'],
      counter_table: ['あり', 'なし'],
      tough_sealers: ['30%', '100%', '150%', 'なし'],
      paidStatus: {
        0: '無償',
        1: '有償'
      }
    }
  },
  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('YYYY/MM/DD')
      }
    }
  }
}
</script>

<style lang="scss" src="./WorkInformation.scss" scoped>
</style>
