<template>
  <validation-observer ref="observer">
    <div>
      <v-dialog v-model="dialog.edit_customer" persistent max-width="1200px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" rounded color="white">
            編集
            <v-icon right size="20">
              $write
            </v-icon>
          </v-btn>
        </template>
        <v-card class="pa-10">
          <v-card-title class="justify-space-between mb-5">
            <div class="page-title font-weight-bold">
              顧客情報の編集
            </div>
            <v-btn
              color="#757575"
              min-width="12"
              max-width="12"
              @click="dialog.edit_customer = false"
              text
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <div class="customer-box py-10 px-16">
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  顧客名
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="name"
                    rules="required|max:50"
                  >
                    <v-text-field
                      class="form-text"
                      v-model="data.name"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="顧客名を入力してください"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  顧客名（かな）
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="furigana_name"
                    rules=""
                  >
                    <v-text-field
                      class="form-text"
                      v-model="data.furigana_name"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="カタカナ"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  郵便番号
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="postcode"
                    rules=""
                  >
                    <v-text-field
                      class="form-text"
                      v-model="data.postcode"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="000-0000"
                      v-mask="'###-####'"
                      @input="fetchPostcodeData()"
                      max="8"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  都道府県
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <v-select
                    class="form-text"
                    v-model="data.prefecture_id"
                    item-text="name"
                    item-value="id"
                    :items="allPrefectures"
                    dense
                    hide-details
                    outlined
                    placeholder="都道府県"
                    :loading="dataLoading"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  市区町村
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <v-text-field
                    class="form-text"
                    v-model="data.city"
                    dense
                    hide-details
                    outlined
                    placeholder="顧客名を入力してください"
                    :loading="dataLoading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  住所
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="address"
                    rules=""
                  >
                    <v-text-field
                      class="form-text"
                      v-model="data.address"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="住所"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  電話番号
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider
                    v-slot="{ errors }"
                    name="phone1"
                    rules=""
                  >
                    <v-text-field
                      class="form-text"
                      v-model="data.phone1"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="00000000000"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>

              <v-row align="center">
                <v-col cols="2" class="form-header text-right pb-2">
                  顧客メモ
                </v-col>
                <v-col cols="9" class="flex-grow-1 pb-2">
                  <validation-provider v-slot="{ errors }" name="memo" rules="">
                    <v-textarea
                      class="form-text"
                      v-model="data.memo"
                      dense
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      :hide-details="errors.length === 0"
                      outlined
                      placeholder="自由入力"
                      row-height="4"
                    ></v-textarea>
                  </validation-provider>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-row align="center">
              <v-col cols="6">
                <v-btn @click="remove" color="#AD4545" text>
                  <v-icon left>$trash</v-icon>
                  削除する
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right">
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-6"
                  text
                  @click="dialog.edit_customer = false"
                  :loading="loading"
                >
                  キャンセル
                </v-btn>
                <v-btn
                  color="#4F55A7"
                  class="white--text px-10"
                  @click="save"
                  :loading="loading"
                >
                  更新する
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </validation-observer>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters, mapMutations } from 'vuex'
import postal_code from 'japan-postal-code'
import { normalize } from '@geolonia/normalize-japanese-addresses'
export default {
  name: 'EditCustomer',
  props: ['customer'],
  computed: {
    ...mapGetters(['allPrefectures'])
  },
  methods: {
    ...mapMutations(['showModal']),
    async save() {
      this.$refs.observer.validate().then(async success => {
        if (!success) {
          return
        }
        await this.fillLatLong()
        let formData = this.sanitizeData()
        this.loading = true
        this.$store
          .dispatch('CUSTOMER_UPDATE', formData)
          .then(
            response => {
              if (response.data.status === 'success') {
                let payload = {
                  status: response.data.status,
                  message: response.data.data.message
                }
                this.$store.commit('setAlert', payload)

                this.$store.dispatch('CUSTOMER_GET_ALL')
              }
            },
            error => {
              let payload = {
                status: 'error',
                message: 'Something error, please try again'
              }
              this.$store.commit('setAlert', payload)
              throw error
            }
          )
          .finally(() => {
            this.dialog.edit_customer = false
            this.loading = false
          })
      })
    },
    remove() {
      this.showModal({
        title: 'このアクションを実施してもよろしいですか？',
        text:
          '※ 顧客情報を削除すると、それに関連する物件情報、サービス情報、タスク情報など情報も削除されてしまいます。',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    processDelete() {
      let id = this.customer.id
      this.loading = true

      this.$store
        .dispatch('DELETE_CUSTOMER', id)
        .then(
          response => {
            if (response.data.status === 'success') {
              let payload = {
                status: response.data.status,
                message: response.data.data.message
              }
              this.$store.commit('setAlert', payload)
              this.$router.push('/customer/list')
            }
          },
          error => {
            let payload = {
              status: 'error',
              message: 'Something error, please try again'
            }
            this.$store.commit('setAlert', payload)
            throw error
          }
        )
        .finally(() => {
          this.dialog.customer_edit = false
          this.loading = false
        })
    },
    sanitizeData() {
      let data = this.data

      return data
    },
    async fillLatLong() {
      let prefecture = this.allPrefectures.find(
        v => v.id === this.data.prefecture_id
      )
      if (prefecture?.name || this.data.city || this.data.address) {
        await normalize(
          prefecture?.name + this.data.city + this.data.address
        ).then(result => {
          this.data.lng = result.lng
          this.data.lat = result.lat
        })
      }
    },
    fetchPostcodeData: debounce(function() {
      this.dataLoading = true
      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.allPrefectures.find(
          prefecture => prefecture.name === address.prefecture
        )
        if (selected_prefecture) {
          this.data.prefecture = selected_prefecture.name
          this.data.prefecture_id = selected_prefecture.id
          this.data.city = address.city
        }
      })
      this.dataLoading = false
    }, 500)
  },
  data() {
    return {
      dialog: {
        edit_customer: false
      },
      loading: false,
      dataLoading: false,
      data: JSON.parse(JSON.stringify(this.customer))
    }
  }
}
</script>
<style lang="scss" src="./AddSite.scss" scoped></style>
