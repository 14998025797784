<template>
  <v-row>
    <v-col cols="12">
      <customer-information
        :customer="customer"
        :prefectures="allPrefectures"
      />
    </v-col>

    <v-col cols="12">
      <property-description
        :customer="customer"
        :selectedService.sync="selectedService"
        :serviceSite="serviceSite"
        :prefectures="allPrefectures"
        @change:site="updateCustomerData('site', $event)"
        @change:service="updateCustomerData('service', $event)"
      />
    </v-col>
    
    <v-col cols="12">
      <property-information
        :customer="customer"
        :selectedService="selectedService"
      />
    </v-col>

    <v-col cols="12">
      <work-information
        :projectId="projectId"
        :customer="customer"
        :selectedService="selectedService"
      />
    </v-col>
  </v-row>
</template>

<script>
import CustomerInformation from '@/components/admin/partials/Customer/View/Basic/Informations/CustomerInformation'
import PropertyDescription from '@/components/admin/partials/Customer/View/Basic/Informations/PropertyDescription'
import PropertyInformation from '@/components/admin/partials/Customer/View/Basic/Informations/PropertyInformation'
import WorkInformation from '@/components/admin/partials/Customer/View/Basic/Informations/WorkInformation'
import { mapGetters } from 'vuex'

export default {
  name: 'Basic',
  components: {
    WorkInformation,
    PropertyInformation,
    PropertyDescription,
    CustomerInformation
  },
  props: {
    customer: {
      type: Object,
      required: true
    },
    projectId: {
      type: [Number, String]
    }
  },
  computed: {
    ...mapGetters(['allPrefectures']),
    selectedService: {
      get() {
        return this.projectId
      },
      set(value) {
        this.$emit('update:project-id', value)
      }
    },
    siteId() {
      return parseInt(this.$route.params?.site_id)
    },
    sites() {
      return this.customer.sites
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id === this.siteId)
    },
    projects() {
      return this.selectedSite?.projects
    },
    serviceSite() {
      return this.projects?.map(project => {
        return {
          ...project.service_type,
          project_id: project.id
        }
      })
    }
  },
  methods: {
    updateCustomerData(type, value) {
      let project
      if (type === 'site') {
        project = this.sites.find(site => site.id === value)?.projects[0]
      } else {
        project = this.projects.find(project => project.id === value)
      }
      let param = {
        id: this.customer.id,
        customer_id: this.customer.id,
        site_id: this.siteId,
        project_id: value,
        paginate: 10
      }
      if (project.service_type_id === 0) {
        param.coating_id = project?.svc_coating_id
        this.$store.dispatch('CUSTOMER_GET_ALL_AFTER_SERVICE', param)
      } else {
        this.$store.dispatch('CUSTOMER_GET_ALL_CLAIM', param)
      }
    }
  }
}
</script>

<style lang="scss" src="./Basic.scss" scoped></style>
