<template>
  <div>
    <div class="customer-box py-10 px-16">
      <!--        <v-row>-->
      <!--          <v-col cols="12" class="header">-->
      <!--            物件/現場の基本情報-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <v-row align="center">
        <v-col cols="2" class="form-header text-right pb-2">
          メーカー
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType0"
            v-model="project.partner_0_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>

        <v-col cols="2" class="form-header text-right pb-2">
          発注会社
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType1"
            v-model="project.partner_1_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="2" class="form-header text-right pb-2">
          元請会社
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType2"
            v-model="project.partner_2_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
        <v-col cols="2" class="form-header text-right pb-2">
          現場立ち合い
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-btn-toggle
            dense
            class="btn-toggle-worker-type"
            v-model="project.on_site_monitoring"
          >
            <v-btn :value="0">
              有
            </v-btn>

            <v-btn :value="1">
              無
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <ServiceInfo :data="project" />

      <v-row>
        <v-col cols="2" class="form-header text-right pb-2">
          現場に関するメモ
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-textarea
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="現場に関するメモを記入してください。"
            v-model="project.general_guideline"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </div>

    <div id="work">
      <v-row>
        <v-col cols="12" class="mb-8 mt-10">
          <div class="text-title text-title-2 text-heading-1">作業情報</div>
          <div class="horizontal-line"></div>
        </v-col>
      </v-row>
    </div>

    <template v-if="project.service_type_id === 0">
      <EditCoatingService :data="project.svc_coating" />
    </template>

    <template v-else-if="project.service_type_id === 1">
      <EditBuildingMaintenanceService :data="svc_bldg_mnt" />
    </template>

    <template v-else-if="project.service_type_id > 1">
      <EditRenewalService :data="project.svc_other" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceInfo from './Partial/EditService'
import EditCoatingService from './Partial/EditCoatingService'
import EditBuildingMaintenanceService from './Partial/EditBuildingMaintenanceService'
import EditRenewalService from './Partial/EditRenewalService'
export default {
  name: 'SiteInfo',
  components: {
    EditRenewalService,
    EditBuildingMaintenanceService,
    EditCoatingService,
    ServiceInfo
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    },
    selectedService: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters(['allPartners']),
    partnerType0() {
      return this.allPartners.filter(item => item.type === 0)
    },
    partnerType1() {
      return this.allPartners.filter(item => item.type === 1)
    },
    partnerType2() {
      return this.allPartners.filter(item => item.type === 2)
    },
    svc_bldg_mnt() {
      let dataSvc = {}
      dataSvc.floor = this.project?.svc_floor
      dataSvc.carpet = this.project?.svc_carpet
      dataSvc.window = this.project?.svc_window

      return dataSvc
    }
  },
  data() {
    return {
      project: {}
    }
  },
  watch: {
    selectedService() {
      this.project = this.data?.projects.find(project => project.id == this.selectedService)
    }
  },
  created() {
    this.project = this.data?.projects.find(project => project.id == this.selectedService)
    if (this.project.service_type_id === 0) {
      if (this.project.svc_coating.floor_materials) {
        this.project.svc_coating.floor_color = this.project.svc_coating.floor_materials.map(item => item.id)
      }
    }
  }
}
</script>

<style lang="scss" src="./SiteInfo.scss" scoped></style>
