<template>
  <div>
    <template v-for="(field, fieldIndex) in filteredFields">
      <v-container
        class="info-box mb-8 pa-8"
        :key="`form-service-type-building-maintenance-${fieldIndex}`"
      >
        <v-row>
          <v-col cols="auto" class="info-box-headline text-heading-2 mb-4">
            <v-icon class="mr-4" color="#4F55A7" size="6">$dot</v-icon>
            {{ field.name }}
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in field.fields"
          :key="`form-service-type-building-maintenance-${fieldIndex}-${index}`"
          :align="
            item.type === 'checkbox' ||
            item.type === 'textarea' ||
            item.type === 'checkbox-group'
              ? 'start'
              : 'center'
          "
        >
          <v-col
            cols="2"
            class="form-header text-right"
            :class="
              item.type === 'checkbox' || item.type === 'checkbox-group'
                ? ''
                : ''
            "
          >
            {{ item.label }}
          </v-col>
          <v-col
            :cols="
              item.type === 'checkbox' ||
              item.type === 'textarea' ||
              item.type === 'checkbox-group'
                ? '9'
                : 'auto'
            "
            :class="item.type === 'select' ? 'flex-grow-1' : ''"
          >
            <template
              v-if="item.type === 'radio' || item.type === 'radio-checkbox'"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="item.key"
                rules=""
              >
                <v-radio-group
                  v-model="data[field.type][item.key]"
                  row
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  hide-details
                  class="mt-0"
                >
                  <v-radio
                    v-for="(subItem, subIndex) in item.values"
                    :key="`form-service-${item.key}-${subIndex}`"
                    :label="subItem.text"
                    :value="subIndex"
                  >
                  </v-radio>
                </v-radio-group>
              </validation-provider>
            </template>

            <template v-else-if="item.type === 'select'">
              <validation-provider
                v-slot="{ errors }"
                :name="item.key"
                rules=""
              >
                <v-select
                  class="form-text"
                  outlined
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  :hide-details="errors.length === 0"
                  dense
                  :placeholder="item.values"
                  :items="item.items"
                  v-model="data[field.type][item.key]"
                  item-text="text"
                  item-value="id"
                >
                </v-select>
              </validation-provider>
            </template>

            <template
              v-else-if="item.type === 'number' || item.type === 'text'"
            >
              <validation-provider
                v-slot="{ errors }"
                :name="item.key"
                rules=""
              >
                <v-text-field
                  dense
                  outlined
                  class="form-text"
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  :hide-details="errors.length === 0"
                  :type="item.type"
                  v-model="data[field.type][item.key]"
                  :suffix="item.append"
                  :placeholder="item.values"
                ></v-text-field>
              </validation-provider>
            </template>

            <template v-else-if="item.type === 'textarea'">
              <validation-provider
                v-slot="{ errors }"
                :name="item.key"
                rules=""
              >
                <v-textarea
                  dense
                  outlined
                  class="form-text"
                  :error-messages="errors"
                  :error="errors.length !== 0"
                  :hide-details="errors.length === 0"
                  :type="item.type"
                  v-model="data[field.type][item.key]"
                  :placeholder="item.values"
                ></v-textarea>
              </validation-provider>
            </template>

            <template v-else-if="item.type === 'checkbox'">
              <v-row align="center">
                <validation-provider
                  v-slot="{ errors }"
                  :name="item.key"
                  rules=""
                >
                  <v-radio-group
                    :error-messages="errors"
                    :error="errors.length !== 0"
                    row
                    hide-details
                    class="mt-0"
                  >
                    <template v-for="(subItem, subIndex) in item.values">
                      <InputCheckbox
                        :key="`checkbox-${item.key}-${subIndex}`"
                        :subItem="subItem"
                        :dataModel.sync="data[field.type][item.key]"
                      />
                      <template
                        v-if="
                          subItem.extra_type &&
                            getIndexByItem(
                              data[field.type][item.key],
                              subItem
                            ) != -1
                        "
                      >
                        <v-text-field
                          :key="`textfield-${item.key}-${subIndex}`"
                          v-if="subItem.extra_type"
                          v-model="
                            data[field.type][item.key][
                              getIndexByItem(
                                data[field.type][item.key],
                                subItem
                              )
                            ].text_value
                          "
                          dense
                          outlined
                          class="form-text ml-2 mr-2 mb-2"
                          hide-details
                          height="10"
                          :disabled="
                            getIndexByItem(
                              data[field.type][item.key],
                              subItem
                            ) === -1
                          "
                          @input="
                            changeInput(
                              $event,
                              data[field.type][item.key],
                              subItem
                            )
                          "
                        >
                        </v-text-field>
                      </template>
                    </template>
                  </v-radio-group>
                </validation-provider>
              </v-row>
            </template>

            <template v-if="item.type === 'checkbox-group'">
              <div class="mb-4">
                <v-row
                  v-for="(subItem, subIndex) in item.fields"
                  :key="`checkbox-group-${index}-${subIndex}`"
                >
                  <v-col cols="1" class="form-header text-left pr-0 pb-0">
                    {{ subItem.label }}
                  </v-col>
                  <v-col cols="11" class="pb-0">
                    <!--                  <validation-provider-->
                    <!--                    v-slot="{ errors }"-->
                    <!--                    :name="item.key[subItem.key]"-->
                    <!--                    rules=""-->
                    <!--                  >-->
                    <template v-if="subItem.type === 'checkbox'">
                      <v-radio-group row hide-details class="mt-0 pt-0">
                        <v-checkbox
                          v-model="
                            data[field.type][subItem.column][subItem.key]
                          "
                          v-for="(checkbox, checkboxIndex) in subItem.values"
                          :key="
                            `checkbox-group-${index}-${subIndex}-${checkboxIndex}`
                          "
                          :label="checkbox.text"
                          :value="checkbox"
                          class="pr-3 mt-0 pt-0"
                          hide-details
                        >
                        </v-checkbox>
                      </v-radio-group>
                    </template>
                    <template v-else-if="subItem.type === 'text'">
                      <v-text-field
                        dense
                        hide-details
                        class="form-text"
                        outlined
                        v-model="data[field.type][item.key][subItem.key]"
                      ></v-text-field>
                    </template>
                    <!--                  </validation-provider>-->
                  </v-col>
                </v-row>
              </div>
            </template>
          </v-col>

          <template v-if="item.extra">
            <v-col v-if="item.extra" cols="auto" class="pr-4">
              {{ item.extra.label }}
            </v-col>
            <v-col v-if="item.extra" cols="auto">
              <v-text-field
                v-model="data[item.extra.key]"
                class="form-text"
                dense
                hide-details
                outlined
              ></v-text-field>
            </v-col>
          </template>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import InputCheckbox from './Input/InputCheckbox'
import buildingMaintenanceFields from '@/views/customer/Create/Site/Type/buildingMaintenanceFields'
export default {
  name: 'EditBuildingMaintenanceService',
  components: { InputCheckbox },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    filteredFields() {
      return this.fields.map(field => {
        return {
          name: field.name,
          type: field.type,
          fields: field.fields.filter(item => {
            if (this.checkCondition(item)) return item
          })
        }
      })
    }
  },
  data() {
    return {
      fields: buildingMaintenanceFields,
      waxing_place_options: ['1層', '2層', '水拭き仕上げ', 'バフ仕上げ']
    }
  },
  methods: {
    checkCondition(item) {
      return this.evaluateStatement(item.conditional)
    },
    evaluateStatement(condition) {
      if (condition !== undefined) {
        let str = `this.${condition.field} ${condition.operator} ${condition.value}`
        return eval(str)
      }
      return true
    },
    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },
    changeInput(e, data, item) {
      let d = data[this.getIndexByItem(data, item)]
      if (d.hasOwnProperty('text_value') && !e) {
        delete d.text_value
        return
      }
      d.text_value = e
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  &-header {
    font-size: 1.4rem;
  }

  &-text {
    box-shadow: unset;
    ::v-deep {
      .v-input__slot {
        background: #ffffff;
        box-sizing: border-box;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
      }
    }
  }
}

.info-box {
  background: #f8f9ff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  ::v-deep {
    .v-label {
      font-size: 1.4rem;
    }
    .v-input__slot {
      font-size: 1.4rem;
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 1.4rem;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 1.4rem;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 1.4rem;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 1.4rem;
      }
    }
  }

  &-headline {
    color: #4f55a7;
    font-size: 18px;

    &-sub {
      color: #474747;
      font-size: 18px;
      font-weight: bold;
    }
  }

  &-dot {
    background-color: #4f55a7;
    height: 3px;
    width: 3px;
    border-radius: 50%;
    display: inline-block;
  }

  &-work-information {
    &-headline {
      color: #333333;

      &.top {
        font-size: 18px;
        font-weight: 700;
      }

      &.sub {
        font-size: 14px;
      }
    }

    &-content {
      &-label {
        color: #828282;
        font-size: 16px;
      }

      &-content {
        color: #333333;
        font-size: 16px;
      }
    }
  }
}
</style>
