<template>
  <div>
    <v-dialog v-model="dialog.pdf_add" max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="site.file_pdf" v-bind="attrs" v-on="on" rounded color="white">
          編集
          <v-icon right size="20">
            $write
          </v-icon>
        </v-btn>
        <v-btn style="justify-content:left;" v-else small v-bind="attrs" v-on="on" depressed color="#CFD3FE" min-width="140" class="cm-add-btn">
          <v-icon left>mdi-plus</v-icon> {{ "PDF追加" }}
        </v-btn>
      </template>
      <v-card class="pa-10">
        <v-card-title class="justify-space-between mb-5">
          <div class="text-title text-title-2 font-weight-bold">
            PDF管理
          </div>
          <v-btn
            color="#757575"
            min-width="12"
            max-width="12"
            @click="dialog.pdf_add = false"
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="mb-10">
            <v-row align="center">
              <v-col cols="12" class="flex-grow-1">
                <div class="filename" :class="filename ? 'filename-has-file' : ''">
                  {{ filename ? filename : 'PDFファイルがありません。' }}
                </div>
                <input
                  type="file"
                  style="display: none"
                  ref="uploader"
                  accept=".pdf"
                  @change="onFilePicked"
                />
              </v-col>
              <v-col cols="12">
                <div class="d-flex justify-space-between">
                  <v-btn text class="red--text" @click="removeFile()">
                    ｰ 削除
                  </v-btn>
                  <v-btn text class="blue--text pointer" @click="openFile()">
                    + アップロード
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mr-6"
            text
            @click="dialog.pdf_add = false"
            :loading="loading"
          >
            キャンセル
          </v-btn>
          <v-btn
            color="#4F55A7"
            class="white--text px-10"
            @click="save"
            :loading="loading"
          >
            確定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'DialogAddPDF',
  props: ['customer', 'site'],
  computed: {
    filename: {
      get() {
        return this.file_name !== null ? this.file_name : this.site.file_pdf_name
      },
      set(val) {
        this.file_name = val
      }
    }
  },
  methods: {
    limitStr(string, limit) {
      let str = string;

      if (typeof str === 'string' && str.length > limit) {
        str = str.slice(0, limit) + '...';
      }
      
      return str;
    },
    openFile() {
      this.$refs.uploader.click()
    },
    removeFile() {
      this.filename = ''
      this.remove_file = true
      this.file = null
      this.$refs.uploader.value = null;
    },
    onFilePicked(e) {
      this.file = e.target.files[0]
      this.filename = this.file.name
    },
    openPDF() {
      if (this.site.file_pdf) {
        window.open(this.site.file_pdf_url, '_blank');
      }
    },
    save() {
      this.loading = true

      let formData = new FormData()
      formData.append('file_pdf', this.file ? this.file : '')
      formData.append('id', this.site.id)
      if (this.remove_file) {
        formData.append('remove_file', true)
        this.site.pdf = null
      }

      this.$store
        .dispatch('SITE_ADD_PDF', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(
          response => {
            if (response.data.status === 'success') {
              let payload = {
                status: response.data.status,
                message: response.data.data.message
              }
              this.$store.commit('setAlert', payload)
              
              this.$store
                .dispatch('CUSTOMER_GET', this.customer.id)
                .then(() => {
                  this.dialog.pdf_add = false
                  this.loading = false
                })

              this.remove_file = false
            }
          },
          error => {
            let payload = {
              status: 'error',
              message: 'Something error, please try again'
            }
            this.$store.commit('setAlert', payload)
            
            this.dialog.pdf_add = false
            this.loading = false
            throw error
          }
        )
    },
  },
  created() {},
  data() {
    return {
      dialog: {
        pdf_add: false
      },
      loading: false,
      remove_file: false,
      file_name: null
    }
  }
}
</script>
<style lang="scss" src="./AddPDF.scss" scoped></style>
