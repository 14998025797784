<template>
  <v-container class="customer-box">
    <v-row align="center">
      <v-col cols="2">
        <div class="form-header text-heading-2 text-heading-2">
          顧客情報
        </div>
      </v-col>
      <v-col cols="auto">
        <div v-if="customer.furigana_name" class="customer-name-top">
          {{ customer.furigana_name }}
        </div>
        <div class="customer-name-bottom">
          {{ customer.name }}
        </div>
      </v-col>
      <v-col cols="auto" class="flex-grow-1 text-right">
        <EditCustomer :customer="customer" />
      </v-col>
    </v-row>
    <v-container class="mt-4 px-0 py-0">
      <v-row no-gutters>
        <v-col v-if="customer.postcode" cols="2" class="customer-box-detail-headline mb-2"
          >郵便番号</v-col
        >
        <v-col v-if="customer.postcode" cols="10" class="customer-box-detail-content mb-2">
          {{ customer.postcode || '-' }}
        </v-col>
        <template v-if="customerAddress != '-'">
          <v-col cols="2" class="customer-box-detail-headline mb-2">住所</v-col>
          <v-col cols="10" class="customer-box-detail-content mb-2">
            <span class="customer-address" @click="goToAddress(customerAddress)">{{ customerAddress }} <v-icon size="20">$newTab</v-icon></span>
          </v-col>
        </template>
        <v-col v-if="customer.phone1" cols="2" class="customer-box-detail-headline mb-2">電話</v-col>
        <v-col v-if="customer.phone1" cols="10" class="customer-box-detail-content mb-2">
          {{ customer.phone1 || '-' }}
        </v-col>
        <v-col v-if="customer.memo" cols="2" class="customer-box-detail-headline mb-2">メモ</v-col>
        <v-col
          v-if="customer.memo"
          cols="10"
          class="customer-box-detail-content white-space-pre cust-line-height mb-2"
        >
          {{ customer.memo || '-' }}
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import EditCustomer from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/EditCustomer.vue'
export default {
  name: 'CustomerInformation',
  components: { EditCustomer },
  props: {
    customer: {
      required: true
    },
    prefectures: {
      type: Array
    }
  },
  computed: {
    prefecture() {
      return this.prefectures.filter(
        p => p.id == this.customer.prefecture_id
      )[0]
    },
    customerAddress() {
      if (
        !this.customer.prefecture?.name &&
        !this.customer.city &&
        !this.customer.address
      )
        return "-";
      return `${this.customer.prefecture?.name || ""}${this.customer.city || ""}${this.customer.address || ""}`;
    },
  },
  methods: {
    goToAddress(address) {
      if (address != '-') {
        window.open(`https://www.google.com/maps?q=${ address }`, '_blank')
      }
    }
  },
  created() {}
}
</script>

<style lang="scss" src="./CustomerInformation.scss" scoped></style>
