var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "1200px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "cm-add-btn",
          attrs: {
            "small": "",
            "depressed": "",
            "color": "#CFD3FE",
            "min-width": "140",
            "disabled": _vm.availableServices.length === 0
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" サービス追加 ")], 1)];
      }
    }]),
    model: {
      value: _vm.dialog.site_add,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "site_add", $$v);
      },
      expression: "dialog.site_add"
    }
  }, [_c('v-card', {
    staticClass: "pa-10"
  }, [_c('v-card-title', {
    staticClass: "justify-space-between mb-5"
  }, [_c('div', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v(" 物件/サービス追加 ")]), _c('v-btn', {
    attrs: {
      "color": "#757575",
      "min-width": "12",
      "max-width": "12",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog.site_add = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('v-row', {
    staticClass: "mb-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "text-title text-title-2"
  }, [_vm._v("物件情報")]), _c('div', {
    staticClass: "horizontal-line"
  })])], 1), _c('div', {
    staticClass: "customer-box py-10 px-16 mb-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" サービス ")]), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "items": _vm.availableServices,
      "item-text": "name",
      "item-value": "id",
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "未選択"
    },
    model: {
      value: _vm.data.project.service_type_id,
      callback: function callback($$v) {
        _vm.$set(_vm.data.project, "service_type_id", $$v);
      },
      expression: "data.project.service_type_id"
    }
  })], 1)], 1)], 1), _vm.data.project.service_type_id != -1 ? _c('SiteInfo', {
    attrs: {
      "data": _vm.data
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-6",
    attrs: {
      "text": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.dialog.site_add = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    staticClass: "white--text px-10",
    attrs: {
      "color": "#4F55A7",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 登録 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }