var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.claimPagination.records_total > 0 ? _c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('Table', {
    staticClass: "font-weight-medium mb-4",
    attrs: {
      "loading": _vm.customerClaimLoading,
      "headers": _vm.headers,
      "items": _vm.claims,
      "total-records": _vm.claimPagination && _vm.claimPagination.records_total ? _vm.claimPagination.records_total : 0,
      "number-of-pages": _vm.claimPagination && _vm.claimPagination.total_pages ? _vm.claimPagination.total_pages : 1,
      "footer": false
    },
    on: {
      "pagination:update": _vm.updateCustomerClaims
    },
    scopedSlots: _vm._u([{
      key: "item.srno",
      fn: function fn(_ref) {
        var index = _ref.index;
        return [_vm._v(" " + _vm._s(_vm.indexStart + index + 1) + " ")];
      }
    }, {
      key: "item.content",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-container', [_c('v-row', [_c('v-col', {
          staticClass: "form-header text-right",
          attrs: {
            "cols": "2"
          }
        }, [_vm._v("Task ID: ")]), _c('v-col', {
          staticClass: "text-center",
          attrs: {
            "cols": "10"
          }
        }, [_c('v-row', [_c('v-col', {
          staticClass: "task-id",
          attrs: {
            "cols": "1"
          }
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'TaskView',
              params: {
                id: item.task.id
              }
            },
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(item.task.id) + " ")])], 1), _c('v-col', {
          attrs: {
            "cols": "4"
          }
        }, [_vm._v(_vm._s(_vm.getDateFormat(item.task.date, 'ja')))]), _c('v-col', {
          attrs: {
            "cols": "5"
          }
        }, [item.created_by ? _c('div', {
          staticStyle: {
            "margin-bottom": "10px"
          }
        }, [_vm._v(" 投稿者: " + _vm._s(item.created_by.last_name) + " 投稿日時: " + _vm._s(_vm.getDateFormat(item.created_at)) + " ")]) : _vm._e(), item.updated_by ? _c('div', [_vm._v(" 更新者: " + _vm._s(item.updated_by.last_name) + " 更新日時: " + _vm._s(_vm.getDateFormat(item.updated_at)) + " ")]) : _vm._e()])], 1)], 1)], 1), _c('v-row', [_c('v-col', {
          staticClass: "form-header text-right",
          attrs: {
            "cols": "2"
          }
        }, [_vm._v("メンバー:")]), _c('v-col', {
          attrs: {
            "cols": "10"
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [item.leader ? _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(item.leader.last_name + ' ' + item.leader.first_name) + ",")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.taskMembers(item)) + " ")])], 1)], 1), _c('v-col', {
          staticClass: "form-header text-right",
          attrs: {
            "cols": "2"
          }
        }, [_vm._v(" 分類: ")]), _c('v-col', {
          staticClass: "claim-categories",
          attrs: {
            "cols": "10"
          }
        }, _vm._l(item.categories, function (category, index) {
          return _c('v-chip', {
            key: index,
            staticClass: "mr-3 mb-2",
            attrs: {
              "color": "#8C5F5F",
              "text-color": "white",
              "label": "",
              "small": ""
            }
          }, [_vm._v(" " + _vm._s(category.name) + " ")]);
        }), 1), _c('v-col', {
          staticClass: "form-header text-right",
          attrs: {
            "cols": "2"
          }
        }, [_vm._v("クレーム内容: ")]), _c('v-col', {
          staticClass: "claim-des",
          attrs: {
            "cols": "10"
          }
        }, [_vm._v(_vm._s(item.description))]), _c('v-col', {
          staticClass: "form-header text-right",
          attrs: {
            "cols": "2"
          }
        }, [_vm._v(" 対応: ")]), _c('v-col', {
          staticClass: "claim-des",
          attrs: {
            "cols": "10"
          }
        }, [_vm._v(_vm._s(item.response))])], 1)], 1)];
      }
    }, {
      key: "top",
      fn: function fn() {
        return [_c('div', {
          staticClass: "mb-6"
        }, [_c('v-row', {
          attrs: {
            "align": "end"
          }
        }, [_c('v-col', {
          staticClass: "text-heading-1",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 物件に関連するクレーム一覧 ")]), _c('v-col', {
          staticClass: "ml-4 text-heading-3",
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v(" 全 (" + _vm._s(_vm.customerClaimsCount) + ") 件 あります。 ")])], 1)], 1)];
      },
      proxy: true
    }], null, false, 3287034879)
  }, [_vm._v(" --> ")])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }