import { render, staticRenderFns } from "./EditRenewalService.vue?vue&type=template&id=af2aa032&scoped=true&"
import script from "./EditRenewalService.vue?vue&type=script&lang=js&"
export * from "./EditRenewalService.vue?vue&type=script&lang=js&"
import style0 from "./EditRenewalService.vue?vue&type=style&index=0&id=af2aa032&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af2aa032",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VContainer,VRow,VTextarea})
