<template>
  <div>
    <v-dialog v-model="dialog.site_edit" persistent max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" @click="setData()" v-on="on" rounded color="white">
          編集
          <v-icon right size="20">
            $write
          </v-icon>
        </v-btn>
      </template>
      <v-card class="pa-10">
        <v-card-title class="justify-space-between mb-5">
          <div class="page-title font-weight-bold">
            物件情報／サービス情報の編集
          </div>
          <v-btn color="#757575" min-width="12" max-width="12" @click="dialog.site_edit = false" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row class="mb-5">
            <v-col cols="12">
              <div class="text-title text-title-2">物件情報</div>
              <div class="horizontal-line"></div>
            </v-col>
          </v-row>
          <div class="customer-box py-10 px-16 mb-10">
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                物件ID
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.site_id" dense hide-details outlined placeholder="物件ID">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                物件名
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.name" dense hide-details outlined
                  placeholder="顧客名を入力してください"></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                物件名（かな）
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.furigana_name" dense hide-details outlined
                  placeholder="物件名（かな）を入力してください"></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                郵便番号
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.postcode" @input="changePostal" dense hide-details
                  outlined placeholder="0000000"></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                都道府県
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-select class="form-text" v-model="data.prefecture_id" item-text="name" item-value="id"
                  :items="allPrefectures" dense hide-details outlined placeholder="都道府県"></v-select>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                市区町村
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.city" dense hide-details outlined placeholder="市区町村">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                住所
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-text-field class="form-text" v-model="data.address" dense hide-details outlined placeholder="住所">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" class="form-header text-right pb-2">
                サービス
              </v-col>
              <v-col cols="9" class="flex-grow-1">
                <v-select class="form-text" v-model="service_type_id" :items="allServices" disabled item-text="name"
                  item-value="id" dense hide-details outlined placeholder="未選択"></v-select>
              </v-col>
            </v-row>
          </div>

          <SiteInfo v-if="service_type_id != -1" :data="data" :selectedService="selectedService" />
        </v-card-text>
        <v-card-actions>
          <v-row align="center">
            <v-col cols="6">
              <v-btn @click="remove" color="#AD4545" text>
                <v-icon left>$trash</v-icon>
                削除する
              </v-btn>
            </v-col>
            <v-col cols="6" class="text-right">
              <v-spacer></v-spacer>
              <v-btn class="mr-6" text @click="dialog.site_edit = false" :loading="loading">
                キャンセル
              </v-btn>
              <v-btn color="#4F55A7" class="white--text px-10" @click="save" :loading="loading">
                更新する
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SiteInfo from './EditSite/SiteInfo'
import { normalize } from '@geolonia/normalize-japanese-addresses'
export default {
  name: 'DialogEditSite',
  components: { SiteInfo },
  props: ['customer', 'selectedService'],
  computed: {
    ...mapGetters(['allPartners', 'allServices', 'allPrefectures']),
    siteId() {
      return parseInt(this.$route.params.site_id)
    },
    selectedSite() {
      return this.customer.sites?.find(site => site.id === this.siteId)
    },
    partnerType0() {
      return this.allPartners.filter(item => item.type === 0)
    },
    partnerType1() {
      return this.allPartners.filter(item => item.type === 1)
    },
    partnerType2() {
      return this.allPartners.filter(item => item.type === 2)
    },
    service_type_id: {
      get() {
        return this.project?.service_type_id
      },
      set(val) {
        this.project.service_type_id = val
      }
    },
    sanitizeFormData() {
      let site = _.clone(this.data)
      site.project = site.projects.find(
        project => project.id == this.selectedService
      )
      site.project_id = site.projects.find(
        project => project.id == this.selectedService
      ).id
      delete site.projects
      if (site.project.service_type_id === 0) {
        delete site.svc_bldg_mnt
        delete site.svc_other
        /**
         * BREAKING CHANGE: New floor material update. (21/07/2022) By Irfan.
         * Floor color is now either a color or an image that is fetched
         * from the server. The old floor color system is abandoned but the old values
         * are not yet updated. For compatibility reason, any past values that are updated
         * after 21/07/2022 will be deleted and replaced with new values. This piece of code below
         * is to do just that.
         **/
        site.project.svc_coating.floor_color = site.project?.svc_coating?.floor_color.filter(
          item => {
            if (typeof item === 'number') return item
          }
        )

      } else if (site.project.service_type_id === 1) {
        delete site.svc_coating
        delete site.svc_other
      } else if (site.project.service_type_id > 1) {
        delete site.svc_coating
        delete site.svc_bldg_mnt
      } else {
        delete site.svc_coating
        delete site.svc_bldg_mnt
        delete site.svc_other
      }
      return site
    }
  },
  methods: {
    ...mapMutations(['showModal']),
    changeService() {
      this.project.service_type_id = this.data.service_type_id
    },
    setData() {
      this.data = _.clone(this.selectedSite)
    },
    async save() {
      await this.fillLatLong()
      let formData = this.sanitizeFormData
      if(formData.project?.svc_floor?.waxing_direction) {
        let before = formData.project?.svc_floor?.waxing_direction
        let after = Object.assign({}, before)
        formData.project.svc_floor.waxing_direction = Object.assign({}, formData.project?.svc_floor?.waxing_direction)
        formData.project.svc_floor.waxing_place = Object.assign({}, formData.project?.svc_floor?.waxing_place)
      }
      formData.customer_id = this.customer.id
      this.loading = true
      this.$store
        .dispatch('CUSTOMER_UPDATE_SITE', formData)
        .then(
          response => {
            if (response.data.status === 'success') {
              let payload = {
                status: response.data.status,
                message: response.data.data.message
              }
              this.$store.commit('setAlert', payload)

              this.$store.dispatch('CUSTOMER_GET', this.customer.id)
            }
          },
          error => {
            let payload = {
              status: 'error',
              message: 'Something error, please try again'
            }
            this.$store.commit('setAlert', payload)
            throw error
          }
        )
        .finally(() => {
          this.dialog.site_edit = false
          this.key++
          this.loading = false
          this.$emit('update')
        })
    },
    remove() {
      this.showModal({
        text: 'このアクションを実施してよろしいですか',
        action: {
          process: {
            color: 'red',
            text: '実施する'
          },
          cancel: {
            color: 'secondary',
            text: 'キャンセル'
          }
        },
        func: this.processDelete
      })
    },
    async fillLatLong() {
      let prefecture = this.allPrefectures.find(
        v => v.id === this.data.prefecture_id
      )
      if (prefecture?.name || this.data.city || this.data.address) {
        await normalize(
          prefecture?.name + this.data.city + this.data.address
        ).then(result => {
          if (result.lat && result.lng) {
            this.data.lng = result.lng
            this.data.lat = result.lat
          } else if (result[0]?.lat && result[0]?.lng) {
            this.data.lng = result.lng
            this.data.lat = result.lat
          }

        })
      }
    },
    processDelete() {
      let customer_id = this.customer.id
      let site_id = this.data.id
      this.loading = true

      this.$store
        .dispatch('DELETE_CUSTOMER_SITE', { customer_id, site_id })
        .then(
          response => {
            if (response.data.status === 'success') {
              let payload = {
                status: response.data.status,
                message: response.data.data.message
              }
              this.$store.commit('setAlert', payload)
              this.$store.dispatch('CUSTOMER_GET', this.customer.id)
            }
          },
          error => {
            let payload = {
              status: 'error',
              message: 'Something error, please try again'
            }
            this.$store.commit('setAlert', payload)
            throw error
          }
        )
        .finally(() => {
          this.dialog.site_edit = false
          this.loading = false
        })
    },
    changePostal() {
      var postal_code = require('japan-postal-code')
      this.resetPrefectureCity()

      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.allPrefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0]

        this.data.prefecture = selected_prefecture.name
        this.data.prefecture_id = selected_prefecture.id
        this.data.city = address.city
      })
    },
    resetPrefectureCity() {
      this.data.prefecture = null
      this.data.prefecture_id = null
      this.data.city = null
    }
  },
  watch: {
    selectedService() {
      this.project = this.data?.projects.find(
        project => project.id == this.selectedService
      )
    }
  },

  mounted() {
    if (localStorage.getItem('site_edit') == 1) {
      this.dialog = {
        site_edit: true
      }
      localStorage.removeItem('site_edit')
    }
  },
  created() {
    this.setData()
    this.project = this.data?.projects.find(
      project => project.id == this.selectedService
    )
  },
  data() {
    return {
      data: [],
      dialog: {
        site_edit: false
      },
      loading: false,
      project: {}
    }
  }
}
</script>
<style lang="scss" src="./AddSite.scss" scoped>
</style>
