<template>
  <v-container v-if="customerCoatingAfterCount > 0">
    <v-row>
      <v-col cols="12">
        <Table
          :loading="false"
          :headers="[]"
          :items="afterServices"
          :total-records="customerCoatingAfterCount"
          :footer="false"
          disable-pagination
          class="font-weight-medium mb-4"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <div class="table-head">
                <div
                  class="table-content-top d-flex justify-center align-center"
                >
                  #
                </div>
              </div>
              <div
                v-for="(item, index) in items"
                :key="`${index}-${item.name}`"
                class="d-flex align-center single-row justify-space-between"
              >
                <div class="d-flex">
                  <div class="idx-number">
                    <span>
                      {{ index + 1 }}
                    </span>
                  </div>
                  <div class="single-record d-flex">
                    <div class="record">
                      <div class="d-flex first-row">
                        <span> {{ item.date | formatDate }} </span>
                        <div class="d-flex">
                          <span class="column-key">対応種別：</span>
                          <span>{{ paidStatus[item.is_paid] }}</span>
                        </div>
                        <div class="d-flex">
                          <span class="column-key">部屋番号：</span>
                          <span>{{ item.room_number }}</span>
                        </div>
                      </div>
                      <div>
                        <span class="column-key">依頼項目：</span>
                        <span>
                          {{ showText(item.reason) }}
                        </span>
                      </div>
                      <div>
                        <span class="column-key">対応方法：</span>
                        <span
                          >{{ showText(item.method) }}
                        </span>
                      </div>
                      <div>
                        <td>
                          <span class="column-key">対応結果：</span>
                          <span>
                            {{ showText(item.after_treatment) }}</span
                          >
                        </td>
                      </div>
                      <div>
                        <td>
                          <span class="column-key">対応者：</span>
                          <span>
                            {{ showUser(item.users) }}</span
                          >
                        </td>
                      </div>
                      <div class="d-flex">
                          <span class="column-key">対応者初見：</span>
                          <span class="white-space-pre">{{ item.memo || '-' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="text-align:center" class="list-link" v-if="customerCoatingAfterCount > 10" @click="redirectToList()">
              [ 一覧を見る ]
              </div>
            </tbody>
          </template>
          <template v-slot:top>
            <div class="mb-6">
              <v-row align="end">
                <v-col cols="auto" class="text-heading-1">
                  物件に関連したアフター対応一覧
                </v-col>
                <v-col cols="auto" class="ml-4 text-heading-3">
                  全 ({{ customerCoatingAfterCount}}) 件 あります。
                </v-col>
              </v-row>
            </div>
          </template>
        </Table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/plugins/dayjs'
import Table from '@/components/admin/partials/Table/Table'
import UpdateList from '@/components/admin/partials/Customer/View/Basic/UpdateHistory/UpdateList'
export default {
  name: 'AfterService',
  props: {
    customer: {
      type: Object,
      required: true
    },
    projectId: {
      type: [Number, String]
    }
  },
  components: { Table },
  data() {
    return {
      items: {
        after_treatments: [
          {
            text: '成功',
            value: 0
          },
          {
            text: '微妙',
            value: 1
          },
          {
            text: 'お客様納得',
            value: 2
          },
          {
            text: '失敗',
            value: 3
          },
          {
            text: 'その他',
            extra_type: 'with_text',
            value: 5
          }
        ],
        methods: [
          {
            text: '訪問再コート',
            value: 0
          },
          {
            text: '社内持ち込み再コート',
            value: 1
          },
          {
            text: 'スプレーぼかし',
            value: 2
          },
          {
            text: 'リペア含む',
            value: 3
          },

          {
            text: '剥離再コート',
            value: 4
          },
          {
            text: 'アルコール除去',
            value: 5
          },
          {
            text: '重ね散布',
            value: 6
          },

          {
            text: 'その他',
            extra_type: 'with_text',
            value: 7
          }
        ]
      },
      addServiceDialog: false,
      page: 1,
      pageCount: 5,
      headers: [
        { text: '#', align: 'center', value: 'srno', width: 50 },

        {
          text: '日付',
          align: 'center',
          value: 'date',
          width: 50,
          sortable: false
        },

        {
          text: '対応種別',
          align: 'center',
          value: 'is_paid',
          width: 50,
          sortable: false
        },

        {
          text: '部屋番号',
          align: 'center',
          value: 'room_number',
          sortable: false
        },
        {
          text: '依頼項目',
          align: 'center',
          value: 'reason',
          sortable: false
        },

        {
          text: '対応方法',
          align: 'center',
          value: 'method',
          width: 50,
          sortable: false
        },
        {
          text: '対応結果',
          align: 'center',
          value: 'after_treatments',
          width: 50,
          sortable: false
        },
        {
          text: '対応者',
          align: 'center',
          value: 'user',
          sortable: false
        },

        {
          text: '対応者初見',
          align: 'center',
          value: 'memo',
          sortable: false
        }
      ],
      paidStatus: {
        0: '無償',
        1: '有償'
      }
    }
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('YYYY/MM/DD')
      }
      return '-'
    }
  },

  mounted() {
    this.getDataFromApi()
  },
  computed: {
    ...mapGetters([
      'allUsersList',
      'allCustomerCoatingAfters',
      'customerAfterServicePagination',
      'customerAfterServiceLoading',
      'customerCoatingAfterCount'
    ]),
    afterServices() {
      return this.allCustomerCoatingAfters
    },
    project() {
      return this.selectedSite?.projects?.find(
        project => project.id === this.projectId
      )
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id == this.siteId)
    },
    pagination() {
      return this.customerAfterServicePagination
    },
    siteId() {
      return this.$route.params.site_id
    },
  },

  methods: {
    showUser(array) {
      if (!array?.length) return '-'
      return array
        .map(user => `${user.last_name || ''} ${user.first_name || ''}`)
        .join(', ')
    },
    showText(array) {
      if (!array?.length) return '-'
      return array
        .map(
          item => `${item.text || item.name} ${item.extra_type === 'with_text' ? ` (${item.text_value || item.value})` : ''}`
        )
        .join(', ')
    },

    getIndexByItem(data, item) {
      return data
        .map(item => `${item.text}${item.id}`)
        .indexOf(`${item.text}${item.id}`)
    },

    changeInput(e, data, item) {
      let d = data[this.getIndexByItem(data, item)]
      if (d.hasOwnProperty('text_value') && !e) {
        delete d.text_value
        return
      }
      d.text_value = e
    },

    editService(item) {
      this.addServiceDialog = true
      this.claimItem = item
    },

    getDataFromApi() {
      this.getDataFromApiCategory()
    },

    async getDataFromApiCategory() {
      let params = {
        tag_type: 2
      }

      await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL', params)
    },
     redirectToList() {
        this.$router.push({
            name: 'CustomerViewDetailAfterService',
            params: {
                customer_id: this.$route.params?.customer_id,
                site_id: this.$route.params?.site_id
            }
        })
    },
    updateCustomerAfterServices(page) {
      this.page = page
      let param = {
        customer_id: this.$route.params?.customer_id,
        site_id: this.$route.params?.site_id,
        project_id: this.projectId,
        coating_id: this.project?.svc_coating_id,
        entries: 10,
      }
      this.$store.dispatch('CUSTOMER_GET_ALL_AFTER_SERVICE', param)
    }
  }
}
</script>
<style src="./AfterService.scss" lang="scss"></style>
