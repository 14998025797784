var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_vm.site.file_pdf ? _c('v-btn', _vm._g(_vm._b({
          attrs: {
            "rounded": "",
            "color": "white"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 編集 "), _c('v-icon', {
          attrs: {
            "right": "",
            "size": "20"
          }
        }, [_vm._v(" $write ")])], 1) : _c('v-btn', _vm._g(_vm._b({
          staticClass: "cm-add-btn",
          staticStyle: {
            "justify-content": "left"
          },
          attrs: {
            "small": "",
            "depressed": "",
            "color": "#CFD3FE",
            "min-width": "140"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" " + _vm._s("PDF追加") + " ")], 1)];
      }
    }]),
    model: {
      value: _vm.dialog.pdf_add,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "pdf_add", $$v);
      },
      expression: "dialog.pdf_add"
    }
  }, [_c('v-card', {
    staticClass: "pa-10"
  }, [_c('v-card-title', {
    staticClass: "justify-space-between mb-5"
  }, [_c('div', {
    staticClass: "text-title text-title-2 font-weight-bold"
  }, [_vm._v(" PDF管理 ")]), _c('v-btn', {
    attrs: {
      "color": "#757575",
      "min-width": "12",
      "max-width": "12",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog.pdf_add = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('div', {
    staticClass: "mb-10"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "filename",
    class: _vm.filename ? 'filename-has-file' : ''
  }, [_vm._v(" " + _vm._s(_vm.filename ? _vm.filename : 'PDFファイルがありません。') + " ")]), _c('input', {
    ref: "uploader",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".pdf"
    },
    on: {
      "change": _vm.onFilePicked
    }
  })]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    staticClass: "red--text",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.removeFile();
      }
    }
  }, [_vm._v(" ｰ 削除 ")]), _c('v-btn', {
    staticClass: "blue--text pointer",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.openFile();
      }
    }
  }, [_vm._v(" + アップロード ")])], 1)])], 1)], 1)]), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-6",
    attrs: {
      "text": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.dialog.pdf_add = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    staticClass: "white--text px-10",
    attrs: {
      "color": "#4F55A7",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 確定 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }