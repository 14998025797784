var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('customer-information', {
    attrs: {
      "customer": _vm.customer,
      "prefectures": _vm.allPrefectures
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('property-description', {
    attrs: {
      "customer": _vm.customer,
      "selectedService": _vm.selectedService,
      "serviceSite": _vm.serviceSite,
      "prefectures": _vm.allPrefectures
    },
    on: {
      "update:selectedService": function updateSelectedService($event) {
        _vm.selectedService = $event;
      },
      "update:selected-service": function updateSelectedService($event) {
        _vm.selectedService = $event;
      },
      "change:site": function changeSite($event) {
        return _vm.updateCustomerData('site', $event);
      },
      "change:service": function changeService($event) {
        return _vm.updateCustomerData('service', $event);
      }
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('property-information', {
    attrs: {
      "customer": _vm.customer,
      "selectedService": _vm.selectedService
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('work-information', {
    attrs: {
      "projectId": _vm.projectId,
      "customer": _vm.customer,
      "selectedService": _vm.selectedService
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }