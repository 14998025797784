<template>
  <div>
    <v-dialog v-model="dialog.site_add" persistent max-width="1200px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn small v-bind="attrs" v-on="on" depressed color="#CFD3FE" class="cm-add-btn">
          <v-icon left>mdi-plus</v-icon> 物件追加
        </v-btn>
      </template>
      <validation-observer ref="observer">
        <form @submit.prevent="save">
          <v-card class="pa-10">
            <v-card-title class="justify-space-between mb-5">
              <div class="page-title font-weight-bold">
                物件/サービス追加
              </div>
              <v-btn color="#757575" min-width="12" max-width="12" @click="dialog.site_add = false" text>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="mb-5">
                <v-col cols="12">
                  <div class="text-title text-title-2">物件情報</div>
                  <div class="horizontal-line"></div>
                </v-col>
              </v-row>
              <div class="customer-box py-10 px-16 mb-10">
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    物件ID
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.site_id" dense hide-details outlined
                      placeholder="物件ID"></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    物件名
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.name" dense hide-details outlined
                      placeholder="顧客名を入力してください"></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    物件名（かな）
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.furigana_name" dense hide-details outlined
                      placeholder="物件名（かな）を入力してください"></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    郵便番号
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.postcode" @input="changePostal" dense hide-details
                      outlined placeholder="0000000"></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    都道府県
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-select class="form-text" v-model="data.prefecture_id" item-text="name" item-value="id"
                      :items="allPrefectures" dense hide-details outlined placeholder="都道府県"></v-select>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    市区町村
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.city" dense hide-details outlined placeholder="市区町村">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    住所
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <v-text-field class="form-text" v-model="data.address" dense hide-details outlined placeholder="住所">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center">
                  <v-col cols="2" class="form-header text-right pb-2">
                    サービス
                  </v-col>
                  <v-col cols="9" class="flex-grow-1">
                    <validation-provider v-slot="{ errors }" name="service_type_id" rules="required">
                      <v-select class="form-text" v-model="data.project.service_type_id" :error-messages="errors"
                        :error="errors.length !== 0" :hide-details="errors.length === 0" :items="allServices"
                        item-text="name" item-value="id" dense outlined placeholder="未選択"></v-select>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>

              <SiteInfo v-if="data.project.service_type_id != -1" :data="data" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="mr-6" text @click="dialog.site_add = false" :loading="loading">
                キャンセル
              </v-btn>
              <v-btn color="#4F55A7" class="white--text px-10" @click="save" :loading="loading">
                登録
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import emptySiteData from '@/views/customer/emptySiteData'
import SiteInfo from './AddSite/SiteInfo'
export default {
  name: 'DialogAddSite',
  components: { SiteInfo },
  props: ['customer'],
  computed: {
    ...mapGetters(['allPartners', 'allServices', 'allPrefectures']),
    partnerType0() {
      return this.allPartners.filter(item => item.type === 0)
    },
    partnerType1() {
      return this.allPartners.filter(item => item.type === 1)
    },
    partnerType2() {
      return this.allPartners.filter(item => item.type === 2)
    },
    sanitizeFormData() {
      let site = this.data
      if (site.project.service_type_id === 0) {
        delete site.svc_bldg_mnt
        delete site.svc_other
      } else if (site.project.service_type_id === 1) {
        delete site.svc_coating
        delete site.svc_other
      } else if (site.project.service_type_id > 1) {
        delete site.svc_coating
        delete site.svc_bldg_mnt
      } else {
        delete site.svc_coating
        delete site.svc_bldg_mnt
        delete site.svc_other
      }
      return site
    }
  },
  methods: {
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        let formData = this.sanitizeFormData
        formData.customer_id = this.customer.id
        this.loading = true
        this.$store
          .dispatch('CUSTOMER_CREATE_SITE', formData)
          .then(
            response => {
              if (response.data.status === 'success') {
                let payload = {
                  status: response.data.status,
                  message: response.data.data.message
                }
                this.$store.commit('setAlert', payload)

                this.$store.dispatch('CUSTOMER_GET', this.customer.id)
                this.data = { ...emptySiteData }
              }
            },
            error => {
              let payload = {
                status: 'error',
                message: 'Something error, please try again'
              }
              this.$store.commit('setAlert', payload)
              throw error
            }
          )
          .finally(() => {
            this.dialog.site_add = false
            this.loading = false
          })
      })
    },
    changePostal() {
      var postal_code = require('japan-postal-code')
      this.resetPrefectureCity()

      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.allPrefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0]

        this.data.prefecture = selected_prefecture.name
        this.data.prefecture_id = selected_prefecture.id
        this.data.city = address.city
      })
    },
    resetPrefectureCity() {
      this.data.prefecture = null
      this.data.prefecture_id = null
      this.data.city = null
    }
  },
  created() { },
  data() {
    return {
      dialog: {
        site_add: false
      },
      loading: false,
      data: { ...emptySiteData }
    }
  }
}
</script>
<style lang="scss" src="./AddSite.scss" scoped>

</style>
