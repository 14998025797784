<template>
  <div>
    <div class="customer-box py-10 px-16 mb-10">
      <!--        <v-row>-->
      <!--          <v-col cols="12" class="header">-->
      <!--            物件/現場の基本情報-->
      <!--          </v-col>-->
      <!--        </v-row>-->
      <v-row align="center">
        <v-col cols="2" class="form-header text-right pb-2">
          メーカー
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType0"
            v-model="data.project.partner_0_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="2" class="form-header text-right pb-2">
          発注会社
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType1"
            v-model="data.project.partner_1_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="2" class="form-header text-right pb-2">
          元請会社
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-select
            class="form-text"
            dense
            hide-details
            outlined
            placeholder="未選択"
            :items="partnerType2"
            v-model="data.project.partner_2_id"
            item-text="name"
            item-value="id"
            clearable
          ></v-select>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="2" class="form-header text-right pb-2">
          現場立ち合い
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-btn-toggle
            dense
            class="btn-toggle-worker-type"
            v-model="data.project.on_site_monitoring"
          >
            <v-btn :value="0">
              有
            </v-btn>

            <v-btn :value="1">
              無
            </v-btn>
          </v-btn-toggle>
          <span class="unselect" @click="data.project.on_site_monitoring = null">選択解除</span>
        </v-col>
      </v-row>

      <ServiceInfo :data="data.project" />

      <v-row>
        <v-col cols="2" class="form-header text-right pb-2">
          現場に関するメモ
        </v-col>
        <v-col cols="9" class="flex-grow-1">
          <v-textarea
            class="form-text"
            rows="7"
            dense
            hide-details
            outlined
            placeholder="現場に関するメモを記入してください。"
            v-model="data.project.general_guideline"
          >
          </v-textarea>
        </v-col>
      </v-row>
    </div>

    <div id="work">
      <v-row class="mb-5">
        <v-col cols="12">
          <div class="text-title text-title-2">作業情報</div>
          <div class="horizontal-line"></div>
        </v-col>
      </v-row>
    </div>

    <template v-if="data.project.service_type_id === 0">
      <TypeCoatingService :data="data.svc_coating" />
    </template>

    <template v-else-if="data.project.service_type_id === 1">
      <TypeBuildingMaintenanceService :data="data.svc_bldg_mnt" />
    </template>

    <template v-else-if="data.project.service_type_id > 1">
      <TypeRenewalService :data="data.svc_other" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceInfo from '@/views/customer/Create/Site/Type/ServiceInfo'
import TypeCoatingService from '@/views/customer/Create/Site/Type/TypeCoatingService'
import TypeBuildingMaintenanceService from '@/views/customer/Create/Site/Type/TypeBuildingMaintenanceService'
import TypeRenewalService from '@/views/customer/Create/Site/Type/TypeRenewalService'
export default {
  name: 'SiteInfo',
  components: {
    TypeRenewalService,
    TypeBuildingMaintenanceService,
    TypeCoatingService,
    ServiceInfo
  },
  props: {
    data: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  computed: {
    ...mapGetters(['allPartners']),
    partnerType0() {
      return this.allPartners.filter(item => item.type === 0)
    },
    partnerType1() {
      return this.allPartners.filter(item => item.type === 1)
    },
    partnerType2() {
      return this.allPartners.filter(item => item.type === 2)
    }
  }
}
</script>

<style lang="scss" src="./SiteInfo.scss" scoped></style>
