var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.showItemInfos ? _c('v-container', {
    staticClass: "customer-box"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "form-header text-heading-2 mb-5"
  }, [_vm._v(" 物件/現場の基本情報 ")])])], 1), _c('v-container', {
    staticClass: "mt-4 px-0 py-0"
  }, [_vm.project.partner_0 || _vm.detailInfo.on_site_monitorings[_vm.project.on_site_monitoring] || _vm.project.partner_2 || _vm.project.partner_1 ? _c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.project.partner_0 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メーカー ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.project.partner_0 ? _vm.project.partner_0.name : '-') + " ")])] : _vm._e(), _vm.detailInfo.on_site_monitorings[_vm.project.on_site_monitoring] ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("現場立ち合い ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.detailInfo.on_site_monitorings[_vm.project.on_site_monitoring] ? _vm.detailInfo.on_site_monitorings[_vm.project.on_site_monitoring] : '-'))])] : _vm._e(), _vm.project.partner_2 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("発注会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.project.partner_2 ? _vm.project.partner_2.name : '-') + " ")])] : _vm._e(), _vm.project.partner_1 ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("元請会社 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.project.partner_1 ? _vm.project.partner_1.name : '-') + " ")])] : _vm._e()], 2) : _vm._e(), _vm.project.partner_0 || _vm.detailInfo.on_site_monitorings[_vm.project.on_site_monitoring] || _vm.project.partner_2 || _vm.project.partner_1 ? _c('v-divider') : _vm._e(), _vm.project.service_type_id == 0 ? _c('v-row', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.itemInfos, function (info, index) {
    return [info.is_checkbox ? [_vm.project[info.keys].length > 0 ? [_c('v-col', {
      key: "item-info-headline-".concat(index),
      staticClass: "customer-box-detail-headline mb-2",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(info.title) + " ")]), _c('v-col', {
      key: "item-info-content-".concat(index),
      staticClass: "customer-box-detail-content mb-2",
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.showText(_vm.project[info.keys])) + " ")])] : _vm._e()] : [_vm.detailInfo[info.keys][_vm.project[info.keys]] ? [_c('v-col', {
      key: "item-info-headline-".concat(index),
      staticClass: "customer-box-detail-headline mb-2",
      attrs: {
        "cols": "2"
      }
    }, [_vm._v(" " + _vm._s(info.title) + " ")]), _c('v-col', {
      key: "item-info-content-".concat(index),
      staticClass: "customer-box-detail-content mb-2",
      attrs: {
        "cols": "4"
      }
    }, [_vm._v(" " + _vm._s(_vm.detailInfo[info.keys][_vm.project[info.keys]] || '-') + " " + _vm._s(info.has_extra && _vm.project[info.keys + '_text'] ? '(' + _vm.project[info.keys + '_text'] + ')' : '') + " ")])] : _vm._e()]];
  })], 2) : _vm._e(), _vm.project.service_type_id == 1 ? _c('v-row', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.detailInfo.elevator[_vm.project.elevator] ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" エレベータ ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.elevator[_vm.project.elevator] ? _vm.detailInfo.elevator[_vm.project.elevator] : '-') + " ")])] : _vm._e(), _vm.detailInfo.parking[_vm.project.parking] ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 駐車場 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.parking[_vm.project.parking]) + " " + _vm._s(_vm.project.parking_text && _vm.project.parking == 0 ? '(' + _vm.project.parking_text + ')' : '') + " ")])] : _vm._e(), _vm.project.key && _vm.project.key.length ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 鍵 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.showText(_vm.project.key)) + " ")])] : _vm._e()], 2) : _vm._e(), _vm.project.service_type_id > 1 ? _c('v-row', {
    staticClass: "mt-4 mb-4",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.detailInfo.parking[_vm.project.parking] ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 駐車場 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.parking[_vm.project.parking]) + " " + _vm._s(_vm.project.parking_text && _vm.project.parking == 0 ? '(' + _vm.project.parking_text + ')' : '') + " ")])] : _vm._e(), _vm.project.key ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 鍵 ")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.showText(_vm.project.key)) + " ")])] : _vm._e()], 2) : _vm._e(), _vm.project.general_guideline ? [_c('v-divider'), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "no-gutters": "",
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("現場に関するメモ ")]), _c('v-col', {
    staticClass: "customer-box-detail-content text-pre-wrap cust-line-height mb-2",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.project.general_guideline || '-') + " ")])], 1)] : _vm._e()], 2)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }