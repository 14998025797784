var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.filteredFields, function (field, fieldIndex) {
    return [_c('v-container', {
      key: "form-service-type-building-maintenance-".concat(fieldIndex),
      staticClass: "info-box mb-8 pa-8"
    }, [_c('v-row', [_c('v-col', {
      staticClass: "info-box-headline text-heading-2 mb-4",
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-icon', {
      staticClass: "mr-4",
      attrs: {
        "color": "#4F55A7",
        "size": "6"
      }
    }, [_vm._v("$dot")]), _vm._v(" " + _vm._s(field.name) + " ")], 1)], 1), _vm._l(field.fields, function (item, index) {
      return _c('v-row', {
        key: "form-service-type-building-maintenance-".concat(fieldIndex, "-").concat(index),
        attrs: {
          "align": item.type === 'checkbox' || item.type === 'textarea' || item.type === 'checkbox-group' ? 'start' : 'center'
        }
      }, [_c('v-col', {
        staticClass: "form-header text-right",
        class: item.type === 'checkbox' || item.type === 'checkbox-group' ? '' : '',
        attrs: {
          "cols": "2"
        }
      }, [_vm._v(" " + _vm._s(item.label) + " ")]), _c('v-col', {
        class: item.type === 'select' ? 'flex-grow-1' : '',
        attrs: {
          "cols": item.type === 'checkbox' || item.type === 'textarea' || item.type === 'checkbox-group' ? '9' : 'auto'
        }
      }, [item.type === 'radio' || item.type === 'radio-checkbox' ? [_c('validation-provider', {
        attrs: {
          "name": item.key,
          "rules": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref) {
            var errors = _ref.errors;
            return [_c('v-radio-group', {
              staticClass: "mt-0",
              attrs: {
                "row": "",
                "error-messages": errors,
                "error": errors.length !== 0,
                "hide-details": ""
              },
              model: {
                value: _vm.data[field.type][item.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.data[field.type], item.key, $$v);
                },
                expression: "data[field.type][item.key]"
              }
            }, _vm._l(item.values, function (subItem, subIndex) {
              return _c('v-radio', {
                key: "form-service-".concat(item.key, "-").concat(subIndex),
                attrs: {
                  "label": subItem.text,
                  "value": subIndex
                }
              });
            }), 1)];
          }
        }], null, true)
      })] : item.type === 'select' ? [_c('validation-provider', {
        attrs: {
          "name": item.key,
          "rules": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref2) {
            var errors = _ref2.errors;
            return [_c('v-select', {
              staticClass: "form-text",
              attrs: {
                "outlined": "",
                "error-messages": errors,
                "error": errors.length !== 0,
                "hide-details": errors.length === 0,
                "dense": "",
                "placeholder": item.values,
                "items": item.items,
                "item-text": "text",
                "item-value": "id"
              },
              model: {
                value: _vm.data[field.type][item.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.data[field.type], item.key, $$v);
                },
                expression: "data[field.type][item.key]"
              }
            })];
          }
        }], null, true)
      })] : item.type === 'number' || item.type === 'text' ? [_c('validation-provider', {
        attrs: {
          "name": item.key,
          "rules": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref3) {
            var errors = _ref3.errors;
            return [_c('v-text-field', {
              staticClass: "form-text",
              attrs: {
                "dense": "",
                "outlined": "",
                "error-messages": errors,
                "error": errors.length !== 0,
                "hide-details": errors.length === 0,
                "type": item.type,
                "suffix": item.append,
                "placeholder": item.values
              },
              model: {
                value: _vm.data[field.type][item.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.data[field.type], item.key, $$v);
                },
                expression: "data[field.type][item.key]"
              }
            })];
          }
        }], null, true)
      })] : item.type === 'textarea' ? [_c('validation-provider', {
        attrs: {
          "name": item.key,
          "rules": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref4) {
            var errors = _ref4.errors;
            return [_c('v-textarea', {
              staticClass: "form-text",
              attrs: {
                "dense": "",
                "outlined": "",
                "error-messages": errors,
                "error": errors.length !== 0,
                "hide-details": errors.length === 0,
                "type": item.type,
                "placeholder": item.values
              },
              model: {
                value: _vm.data[field.type][item.key],
                callback: function callback($$v) {
                  _vm.$set(_vm.data[field.type], item.key, $$v);
                },
                expression: "data[field.type][item.key]"
              }
            })];
          }
        }], null, true)
      })] : item.type === 'checkbox' ? [_c('v-row', {
        attrs: {
          "align": "center"
        }
      }, [_c('validation-provider', {
        attrs: {
          "name": item.key,
          "rules": ""
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function fn(_ref5) {
            var errors = _ref5.errors;
            return [_c('v-radio-group', {
              staticClass: "mt-0",
              attrs: {
                "error-messages": errors,
                "error": errors.length !== 0,
                "row": "",
                "hide-details": ""
              }
            }, [_vm._l(item.values, function (subItem, subIndex) {
              return [_c('InputCheckbox', {
                key: "checkbox-".concat(item.key, "-").concat(subIndex),
                attrs: {
                  "subItem": subItem,
                  "dataModel": _vm.data[field.type][item.key]
                },
                on: {
                  "update:dataModel": function updateDataModel($event) {
                    return _vm.$set(_vm.data[field.type], item.key, $event);
                  },
                  "update:data-model": function updateDataModel($event) {
                    return _vm.$set(_vm.data[field.type], item.key, $event);
                  }
                }
              }), subItem.extra_type && _vm.getIndexByItem(_vm.data[field.type][item.key], subItem) != -1 ? [subItem.extra_type ? _c('v-text-field', {
                key: "textfield-".concat(item.key, "-").concat(subIndex),
                staticClass: "form-text ml-2 mr-2 mb-2",
                attrs: {
                  "dense": "",
                  "outlined": "",
                  "hide-details": "",
                  "height": "10",
                  "disabled": _vm.getIndexByItem(_vm.data[field.type][item.key], subItem) === -1
                },
                on: {
                  "input": function input($event) {
                    return _vm.changeInput($event, _vm.data[field.type][item.key], subItem);
                  }
                },
                model: {
                  value: _vm.data[field.type][item.key][_vm.getIndexByItem(_vm.data[field.type][item.key], subItem)].text_value,
                  callback: function callback($$v) {
                    _vm.$set(_vm.data[field.type][item.key][_vm.getIndexByItem(_vm.data[field.type][item.key], subItem)], "text_value", $$v);
                  },
                  expression: "\n                          data[field.type][item.key][\n                            getIndexByItem(\n                              data[field.type][item.key],\n                              subItem\n                            )\n                          ].text_value\n                        "
                }
              }) : _vm._e()] : _vm._e()];
            })], 2)];
          }
        }], null, true)
      })], 1)] : _vm._e(), item.type === 'checkbox-group' ? [_c('div', {
        staticClass: "mb-4"
      }, _vm._l(item.fields, function (subItem, subIndex) {
        return _c('v-row', {
          key: "checkbox-group-".concat(index, "-").concat(subIndex)
        }, [_c('v-col', {
          staticClass: "form-header text-left pr-0 pb-0",
          attrs: {
            "cols": "1"
          }
        }, [_vm._v(" " + _vm._s(subItem.label) + " ")]), _c('v-col', {
          staticClass: "pb-0",
          attrs: {
            "cols": "11"
          }
        }, [subItem.type === 'checkbox' ? [_c('v-radio-group', {
          staticClass: "mt-0 pt-0",
          attrs: {
            "row": "",
            "hide-details": ""
          }
        }, _vm._l(subItem.values, function (checkbox, checkboxIndex) {
          return _c('v-checkbox', {
            key: "checkbox-group-".concat(index, "-").concat(subIndex, "-").concat(checkboxIndex),
            staticClass: "pr-3 mt-0 pt-0",
            attrs: {
              "label": checkbox.text,
              "value": checkbox,
              "hide-details": ""
            },
            model: {
              value: _vm.data[field.type][subItem.column][subItem.key],
              callback: function callback($$v) {
                _vm.$set(_vm.data[field.type][subItem.column], subItem.key, $$v);
              },
              expression: "\n                          data[field.type][subItem.column][subItem.key]\n                        "
            }
          });
        }), 1)] : subItem.type === 'text' ? [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "hide-details": "",
            "outlined": ""
          },
          model: {
            value: _vm.data[field.type][item.key][subItem.key],
            callback: function callback($$v) {
              _vm.$set(_vm.data[field.type][item.key], subItem.key, $$v);
            },
            expression: "data[field.type][item.key][subItem.key]"
          }
        })] : _vm._e()], 2)], 1);
      }), 1)] : _vm._e()], 2), item.extra ? [item.extra ? _c('v-col', {
        staticClass: "pr-4",
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v(" " + _vm._s(item.extra.label) + " ")]) : _vm._e(), item.extra ? _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_c('v-text-field', {
        staticClass: "form-text",
        attrs: {
          "dense": "",
          "hide-details": "",
          "outlined": ""
        },
        model: {
          value: _vm.data[item.extra.key],
          callback: function callback($$v) {
            _vm.$set(_vm.data, item.extra.key, $$v);
          },
          expression: "data[item.extra.key]"
        }
      })], 1) : _vm._e()] : _vm._e()], 2);
    })], 2)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }