var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "customer-box"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "form-header text-heading-2 text-heading-2"
  }, [_vm._v(" 顧客情報 ")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.customer.furigana_name ? _c('div', {
    staticClass: "customer-name-top"
  }, [_vm._v(" " + _vm._s(_vm.customer.furigana_name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "customer-name-bottom"
  }, [_vm._v(" " + _vm._s(_vm.customer.name) + " ")])]), _c('v-col', {
    staticClass: "flex-grow-1 text-right",
    attrs: {
      "cols": "auto"
    }
  }, [_c('EditCustomer', {
    attrs: {
      "customer": _vm.customer
    }
  })], 1)], 1), _c('v-container', {
    staticClass: "mt-4 px-0 py-0"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.customer.postcode ? _c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("郵便番号")]) : _vm._e(), _vm.customer.postcode ? _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.customer.postcode || '-') + " ")]) : _vm._e(), _vm.customerAddress != '-' ? [_c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("住所")]), _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "10"
    }
  }, [_c('span', {
    staticClass: "customer-address",
    on: {
      "click": function click($event) {
        return _vm.goToAddress(_vm.customerAddress);
      }
    }
  }, [_vm._v(_vm._s(_vm.customerAddress) + " "), _c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("$newTab")])], 1)])] : _vm._e(), _vm.customer.phone1 ? _c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("電話")]) : _vm._e(), _vm.customer.phone1 ? _c('v-col', {
    staticClass: "customer-box-detail-content mb-2",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.customer.phone1 || '-') + " ")]) : _vm._e(), _vm.customer.memo ? _c('v-col', {
    staticClass: "customer-box-detail-headline mb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("メモ")]) : _vm._e(), _vm.customer.memo ? _c('v-col', {
    staticClass: "customer-box-detail-content white-space-pre cust-line-height mb-2",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.customer.memo || '-') + " ")]) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }