var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer"
  }, [_c('div', [_c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "1200px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "rounded": "",
            "color": "white"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" 編集 "), _c('v-icon', {
          attrs: {
            "right": "",
            "size": "20"
          }
        }, [_vm._v(" $write ")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog.edit_customer,
      callback: function callback($$v) {
        _vm.$set(_vm.dialog, "edit_customer", $$v);
      },
      expression: "dialog.edit_customer"
    }
  }, [_c('v-card', {
    staticClass: "pa-10"
  }, [_c('v-card-title', {
    staticClass: "justify-space-between mb-5"
  }, [_c('div', {
    staticClass: "page-title font-weight-bold"
  }, [_vm._v(" 顧客情報の編集 ")]), _c('v-btn', {
    attrs: {
      "color": "#757575",
      "min-width": "12",
      "max-width": "12",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialog.edit_customer = false;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('div', {
    staticClass: "customer-box py-10 px-16"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 顧客名 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "rules": "required|max:50"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "顧客名を入力してください"
          },
          model: {
            value: _vm.data.name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "name", $$v);
            },
            expression: "data.name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 顧客名（かな） ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "furigana_name",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "カタカナ"
          },
          model: {
            value: _vm.data.furigana_name,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "furigana_name", $$v);
            },
            expression: "data.furigana_name"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 郵便番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "postcode",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-text-field', {
          directives: [{
            name: "mask",
            rawName: "v-mask",
            value: '###-####',
            expression: "'###-####'"
          }],
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "000-0000",
            "max": "8"
          },
          on: {
            "input": function input($event) {
              return _vm.fetchPostcodeData();
            }
          },
          model: {
            value: _vm.data.postcode,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "postcode", $$v);
            },
            expression: "data.postcode"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 都道府県 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-select', {
    staticClass: "form-text",
    attrs: {
      "item-text": "name",
      "item-value": "id",
      "items": _vm.allPrefectures,
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "都道府県",
      "loading": _vm.dataLoading
    },
    model: {
      value: _vm.data.prefecture_id,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "prefecture_id", $$v);
      },
      expression: "data.prefecture_id"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 市区町村 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-text-field', {
    staticClass: "form-text",
    attrs: {
      "dense": "",
      "hide-details": "",
      "outlined": "",
      "placeholder": "顧客名を入力してください",
      "loading": _vm.dataLoading
    },
    model: {
      value: _vm.data.city,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "city", $$v);
      },
      expression: "data.city"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 住所 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "address",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "住所"
          },
          model: {
            value: _vm.data.address,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "address", $$v);
            },
            expression: "data.address"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 電話番号 ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "phone1",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('v-text-field', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "00000000000"
          },
          model: {
            value: _vm.data.phone1,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "phone1", $$v);
            },
            expression: "data.phone1"
          }
        })];
      }
    }])
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "form-header text-right pb-2",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 顧客メモ ")]), _c('v-col', {
    staticClass: "flex-grow-1 pb-2",
    attrs: {
      "cols": "9"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "memo",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('v-textarea', {
          staticClass: "form-text",
          attrs: {
            "dense": "",
            "error-messages": errors,
            "error": errors.length !== 0,
            "hide-details": errors.length === 0,
            "outlined": "",
            "placeholder": "自由入力",
            "row-height": "4"
          },
          model: {
            value: _vm.data.memo,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "memo", $$v);
            },
            expression: "data.memo"
          }
        })];
      }
    }])
  })], 1)], 1)], 1)]), _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "#AD4545",
      "text": ""
    },
    on: {
      "click": _vm.remove
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("$trash")]), _vm._v(" 削除する ")], 1)], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "mr-6",
    attrs: {
      "text": "",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        _vm.dialog.edit_customer = false;
      }
    }
  }, [_vm._v(" キャンセル ")]), _c('v-btn', {
    staticClass: "white--text px-10",
    attrs: {
      "color": "#4F55A7",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v(" 更新する ")])], 1)], 1)], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }