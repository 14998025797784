<template>
  <div>
    <v-container class="customer-box">
      <v-row>
        <v-col cols="6" class="form-header text-heading-2">
          物件概要
        </v-col>
        <v-col cols="6" class="text-right">
          <DialogEditSite
            ref="edit_site"
            :selectedService.sync="_selectedService"
            :customer="customer"
            :key="key"
            @update="update()"
          />
        </v-col>
      </v-row>

      <v-row align="center" no-gutters>
        <v-col cols="2" class="customer-box-detail-headline mb-2">
          物件名
        </v-col>
        <v-col
          cols="10"
          class="property-name customer-box-detail-content text-heading-1 mb-2"
        >
          <small>{{ selectedSite.furigana_name }}</small>
          <v-row>
            <v-col>
              <v-select
                class="big-form"
                v-model="siteId"
                item-text="name"
                item-value="id"
                :items="allSite"
                dense
                hide-details
                @change="$emit('change:site', $event)"
              >
              </v-select>
            </v-col>
            <v-col>
              <DialogAddSite :customer="customer" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters>
        <v-col
          v-if="selectedSite.postcode"
          cols="2"
          class="customer-box-detail-headline mb-3"
          >郵便番号</v-col
        >
        <v-col
          v-if="selectedSite.postcode"
          cols="10"
          class="customer-box-detail-content mb-3"
        >
          {{ selectedSite.postcode || '-' }}
        </v-col>
      </v-row>

      <v-row align="center" no-gutters>
        <v-col
          v-if="prefecture && (selectedSite.city || selectedSite.address)"
          cols="2"
          class="customer-box-detail-headline mb-3"
          >所在地</v-col
        >
        <v-col
          v-if="prefecture && (selectedSite.city || selectedSite.address)"
          cols="10"
          class="customer-box-detail-content text-heading-3 mb-3"
        >
          <span
            class="customer-address"
            @click="
              goToAddress(
                (prefecture ? prefecture.name : '') +
                  selectedSite.city +
                  selectedSite.address
              )
            "
          >
            {{ prefecture ? prefecture.name : '' }}{{ selectedSite.city
            }}{{ selectedSite.address }}
            <v-icon size="20">$newTab</v-icon>
          </span>
        </v-col>
        <v-col
          align-self="start"
          cols="2"
          class="customer-box-detail-headline mb-2"
          >サービス</v-col
        >
        <v-col cols="10" class="customer-box-detail-content">
          <v-row>
            <v-col cols="12">
              <v-radio-group
                v-model="_selectedService"
                hide-details
                @change="$emit('change:service', $event)"
              >
                <!-- <v-radio
                v-for="(radio, index) in radios"
                :key="`radio-${index}`"
                :label="radio"
              >
              </v-radio> -->
                <v-radio
                  v-for="(radio, index) in _serviceSite"
                  :key="`radio-${index}`"
                  :value="radio.project_id"
                  :label="radio.name"
                >
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <div class="d-flex">
                <DialogAddService
                  @setData="setData"
                  :site_id="selectedSite.id"
                  :customer="customer"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters>
        <v-col
          cols="2"
          align-self="center"
          class="customer-box-detail-headline mb-3"
          >PDF</v-col
        >
        <v-col cols="10" class="customer-box-detail-content mb-3">
          <div class=" mt-4 d-flex align-center">
            <a
              :href="selectedSite.file_pdf_url"
              target="_blank"
              v-if="selectedSite.file_pdf"
              class="mr-4 blue--text text-decoration-underline"
              >{{ selectedSite.file_pdf_name }}</a
            >
            <DialogAddPDF :site="selectedSite" :customer="customer" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DialogAddSite from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/AddSite.vue'
import DialogEditSite from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/EditSite.vue'
import DialogAddService from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/AddService.vue'
import DialogAddPDF from '@/components/admin/partials/Customer/View/Basic/Informations/Dialog/AddPDF.vue'
export default {
  name: 'PropertyDescription',
  components: { DialogEditSite, DialogAddSite, DialogAddService, DialogAddPDF },
  props: {
    customer: {
      required: true
    },
    selectedService: {
      type: Number,
      required: true
    },
    serviceSite: {
      type: Array,
      required: true
    },
    prefectures: {
      type: Array
    }
  },
  computed: {
    _serviceSite: {
      get() {
        return this.serviceSite
      }
    },
    _selectedService: {
      get() {
        return this.selectedService
      },
      set(value) {
        this.$emit('update:selected-service', value)
      }
    },
    allSite() {
      return this.customer.sites.map(site => {
        return {
          id: site.id,
          name: `${site.site_id || ''} ${site.name}`
        }
      })
    },
    siteId: {
      get() {
        return parseInt(this.$route.params?.site_id)
      },
      set(val) {
        this.$router.replace({
          name: 'CustomerViewDetailBasic',
          params: {
            customer_id: this.customer.id,
            site_id: val
          }
        })
      }
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id === this.siteId)
    },
    services() {
      return this.$store.getters.allServices
    },
    prefecture() {
      return this.prefectures.find(
        prefecture => prefecture.id === this.selectedSite.prefecture_id
      )
    },
    apiUrl(){
      return process.env.VUE_APP_API_URL
    }
  },
  methods: {
    setData() {
      this.$refs.edit_site[0].setData()
    },
    goToAddress(address) {
      if (address != '-') {
        window.open(`https://www.google.com/maps?q=${address}`, '_blank')
      }
    },
    update() {
      this.key++
    }
  },
  data() {
    return {
      radios: ['コーティング ', 'ビルメンテナンス', 'リニューアル'],
      key: 0
    }
  },
  mounted() {
    this.$emit('change:service', this._selectedService)
  }
}
</script>

<style lang="scss" src="./PropertyDescription.scss" scoped></style>
