var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.project.service_type_id == 0 ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("作業情報 ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "txt-headline",
    attrs: {
      "cols": "12"
    }
  }, [_vm._l(_vm.project.svc_coating.coating_genre, function (genre, index) {
    return _c('span', {
      key: "".concat(index, "-").concat(genre)
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.coating_genres[genre]) + " "), index !== _vm.project.svc_coating.coating_genre.length - 1 ? _c('span', [_vm._v(" , ")]) : _vm._e()])]);
  }), _c('span', {
    staticClass: "ml-4"
  }, [_vm._v(_vm._s(_vm.svc_coating.coating_area ? _vm.svc_coating.coating_area : 0) + " ㎡")])], 2), _c('v-col', {
    staticClass: "txt-para",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_coating.coating_genre === 3 ? _vm.svc_coating.coating_range2_text : _vm.svc_coating.coating_range1_text) + " ")]), _c('v-col', {
    staticClass: "mt-5",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("床材の色")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3 d-flex",
    attrs: {
      "cols": "8"
    }
  }, [_vm.svc_coating.floor_materials.length !== 0 ? _vm._l(_vm.svc_coating.floor_materials, function (floorMaterial, index) {
    return _c('div', {
      key: "coating-color-".concat(index),
      staticClass: "d-flex align-center"
    }, [_c('v-avatar', {
      staticClass: "mr-4",
      staticStyle: {
        "border-radius": "5% !important"
      },
      attrs: {
        "tile": "",
        "min-height": "65",
        "min-width": "100",
        "color": floorMaterial.type === 0 ? floorMaterial.color_code : ''
      }
    }, [floorMaterial.type === 1 ? _c('v-img', {
      attrs: {
        "min-height": "65",
        "min-width": "100",
        "src": floorMaterial.image_url
      }
    }) : _vm._e()], 1), _c('span', {
      staticClass: "mr-4"
    }, [_vm._v(" " + _vm._s(floorMaterial.name) + " "), floorMaterial.pivot.custom_value ? [_vm._v(" ( " + _vm._s(floorMaterial.pivot.custom_value) + " ) ")] : _vm._e()], 2)], 1);
  }) : _vm._l(_vm.svc_coating.floor_color, function (floor, index) {
    return _c('span', {
      key: "coating-color-".concat(index),
      staticClass: "d-flex align-center mr-3",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "dot mr-1",
      style: {
        backgroundColor: floor.color
      }
    }), _vm._v(" " + _vm._s(floor.text) + " "), floor.id ? [_vm._v(" (" + _vm._s(floor.text_value) + ") ")] : _vm._e()], 2);
  })], 2), _vm.floor_genres[_vm.svc_coating.floor_genre] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("床材種類")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.floor_genres[_vm.svc_coating.floor_genre] + ' (' + _vm.svc_coating.floor_genre_memo + ')') + " ")])] : _vm._e(), _vm.tough_sealers[_vm.svc_coating.tough_sealer] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("タフシーラー")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.tough_sealers[_vm.svc_coating.tough_sealer]) + " ")])] : _vm._e(), _vm.removal_works[_vm.svc_coating.removal_work] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("剥離作業")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.removal_works[_vm.svc_coating.removal_work]) + " "), _vm.svc_coating.removal_work === 0 ? _c('span', [_vm._v("( " + _vm._s(_vm.project.svc_coating.memo) + " )")]) : _vm._e()])] : _vm._e(), _vm.micro_mops[_vm.svc_coating.micro_mop] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("マイクロモップ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.micro_mops[_vm.svc_coating.micro_mop]) + " ")])] : _vm._e(), _vm.premium_cleaners[_vm.svc_coating.premium_cleaner] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("プレミアム専用クリーナー ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.premium_cleaners[_vm.svc_coating.premium_cleaner]) + " ")])] : _vm._e(), _vm.counter_table[_vm.svc_coating.counter_table] ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("多目的カウンター ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.counter_table[_vm.svc_coating.counter_table]) + " ")])] : _vm._e(), _vm.project.svc_coating.assigned_partner ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("外部発注 ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.project.svc_coating.assigned_partner) + " ")])] : _vm._e(), _vm.project.svc_coating.partner_workrange && _vm.project.svc_coating.assigned_partner ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("発注範囲 ")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 white-space-preline",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" " + _vm._s(_vm.project.svc_coating.partner_workrange) + " ")])] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm.project.service_type_id == 0 && _vm.svc_coating.premium_guard.length ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("プレミアムガード ")], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', _vm._l(_vm.svc_coating.premium_guard, function (item) {
    return _c('v-col', {
      key: item.text,
      staticClass: "info-box-work-information-headline sub"
    }, [_vm._v(" " + _vm._s(item.text) + " "), item.text_value ? [_vm._v(" (" + _vm._s(item.text_value) + ") ")] : _vm._e()], 2);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.project.service_type_id == 0 && _vm.svc_coating.campaign ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("キャンペーンサービス ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-work-information-headline sub",
    attrs: {
      "cols": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_coating.campaign) + " ")])], 1)], 1)], 1)], 1) : _vm._e(), _vm.project.service_type_id == 0 && _vm.svc_coating.coating_option.length ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("サービス施工 ")], 1), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', _vm._l(_vm.svc_coating.coating_option, function (item) {
    return _c('v-col', {
      key: item.text,
      staticClass: "info-box-work-information-headline sub"
    }, [_vm._v(" " + _vm._s(item.text) + " "), item.text_value ? [_vm._v(" (" + _vm._s(item.text_value) + ") ")] : _vm._e()], 2);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.project.service_type_id == 1 ? [_vm.svc_floor.prep_text || _vm.svc_floor.detergent_text || _vm.svc_floor.detergent_method_text || _vm.svc_floor.cleaning_method_text || _vm.svc_floor.polisher_text || _vm.svc_floor.cleaning_machine_text || _vm.svc_floor.pat.length || _vm.svc_floor.certain_place_method_text || _vm.svc_floor.certain_place_explanation || _vm.svc_floor.waxing_place_text || _vm.svc_floor.waxing_product_text || _vm.svc_floor.waxing_rule ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v("共用部洗浄 床洗浄ワックス ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.svc_floor.prep_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("清掃前準備")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.prep_text) + " ")])] : _vm._e(), _vm.svc_floor.detergent_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用洗剤")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.detergent_text) + " ")])] : _vm._e(), _vm.svc_floor.detergent_method_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("洗剤塗布方法")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.detergent_method_text) + " ")])] : _vm._e(), _vm.svc_floor.cleaning_method_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("基本洗浄方法")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.cleaning_method_text) + " ")])] : _vm._e(), _vm.svc_floor.polisher_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用ポリッシャー")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.polisher_text) + " ")])] : _vm._e(), _vm.svc_floor.cleaning_machine_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用自洗機")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.cleaning_machine_text) + " ")])] : _vm._e(), _vm.svc_floor.pat.length ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用パット")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3 d-flex",
    attrs: {
      "cols": "9"
    }
  }, _vm._l(_vm.svc_floor.pat, function (pat, index) {
    return _c('span', {
      key: "coating-color-".concat(index),
      staticClass: "d-flex align-center mr-3",
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "dot mr-1",
      style: {
        backgroundColor: pat.color
      }
    }), _vm._v(" " + _vm._s(pat.text) + " ")]);
  }), 0)] : _vm._e(), _vm.svc_floor.certain_place_method_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("場所指定洗浄方法")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.certain_place_method_text) + " ")])] : _vm._e(), _c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("指定場所")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 cust-line-height",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.certain_place_explanation ? _vm.svc_floor.certain_place_explanation : '-') + " ")])], 2), _c('v-divider', {
    staticClass: "my-8"
  }), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.svc_floor.waxing_place_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("範囲指定")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.svc_floor.waxing_place_text)
    }
  })] : _vm._e(), _vm.svc_floor.waxing_product_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用ワックス")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.waxing_product_text) + " ")])] : _vm._e(), _vm.svc_floor.waxing_rule ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("注意事項")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 cust-line-height",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_floor.waxing_rule) + " ")])] : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _vm.svc_window.stepladder_text || _vm.svc_window.extension_pole_text || _vm.svc_window.safety_helmet_text || _vm.svc_window.safety_belt_text || _vm.svc_window.outside_text || _vm.svc_window.inside_text || _vm.svc_window.certain_window_method_text || _vm.svc_window.certain_window_explanation || _vm.svc_window.certain_window_rule ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" 窓ガラス清掃 ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.svc_window.stepladder_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用脚立")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.stepladder_text) + " ")])] : _vm._e(), _vm.svc_window.extension_pole_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("延長ポール")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.extension_pole_text) + " ")])] : _vm._e(), _vm.svc_window.safety_helmet_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("ヘルメット")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.safety_helmet_text) + " ")])] : _vm._e(), _vm.svc_window.safety_belt_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("安全帯")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.safety_belt_text) + " ")])] : _vm._e(), _vm.svc_window.outside_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("外面")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.outside_text) + " ")])] : _vm._e(), _vm.svc_window.inside_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("内面")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.inside_text) + " ")])] : _vm._e(), _vm.svc_window.certain_window_method_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("場所指定特記事項")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.certain_window_method_text) + " ")])] : _vm._e(), _vm.svc_window.certain_window_explanation ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("指定場所")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3 cust-line-height",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.certain_window_explanation) + " ")])] : _vm._e(), _vm.svc_window.certain_window_rule ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("注意事項")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 cust-line-height",
    staticStyle: {
      "white-space": "pre-line"
    },
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_window.certain_window_rule) + " ")])] : _vm._e()], 2)], 1)], 1)], 1) : _vm._e(), _vm.svc_carpet.prep_text || _vm.svc_carpet.pre_task_text || _vm.svc_carpet.washing_text || _vm.svc_carpet.machine_text || _vm.svc_carpet.stain_removal_text || _vm.svc_carpet.wastewater_text ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "info-box-headline text-heading-2",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "color": "#4F55A7",
      "size": "6"
    }
  }, [_vm._v("$dot")]), _vm._v(" カーペット洗浄 ")], 1), _c('v-col', {
    staticClass: "flex-grow-1",
    attrs: {
      "cols": "9"
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_vm.svc_carpet.prep_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("作業前工程")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.prep_text) + " ")])] : _vm._e(), _vm.svc_carpet.pre_task_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("前処理作業")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.pre_task_text) + " ")])] : _vm._e(), _vm.svc_carpet.washing_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("洗浄作業")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.washing_text) + " ")])] : _vm._e(), _vm.svc_carpet.machine_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("使用バルチャー")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.machine_text) + " ")])] : _vm._e(), _vm.svc_carpet.stain_removal_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3 pb-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("染み抜き")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3 pb-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.stain_removal_text) + " ")])] : _vm._e(), _vm.svc_carpet.wastewater_text ? [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-heading-3",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("汚水回収")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-heading-3",
    attrs: {
      "cols": "9"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_carpet.wastewater_text) + " ")])] : _vm._e()], 2)], 1)], 1)], 1) : _vm._e()] : _vm._e(), _vm.project.service_type_id > 1 ? _c('div', {
    staticClass: "info-box mb-6 pa-8"
  }, [_c('v-row', {
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-center",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("作業内容1")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-pre-wrap",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_other.task_description_1) + " ")])], 1), _c('v-row', {
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-center",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("作業内容2")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-pre-wrap",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_other.task_description_2) + " ")])], 1), _c('v-row', {
    attrs: {
      "align": "baseline"
    }
  }, [_c('v-col', {
    staticClass: "info-box-work-information-content-label text-center",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("作業内容3")]), _c('v-col', {
    staticClass: "info-box-work-information-content-content text-pre-wrap",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.svc_other.task_description_3) + " ")])], 1)], 1) : _vm._e(), _vm.project.service_type_id == 0 ? _c('div', {
    staticClass: "mb-6"
  }, [_c('AfterService', {
    attrs: {
      "projectId": _vm.projectId,
      "customer": _vm.customer
    }
  })], 1) : _vm.project.service_type_id != 0 ? _c('div', [_c('Claim', {
    attrs: {
      "projectId": _vm.projectId,
      "customer": _vm.customer
    }
  })], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }