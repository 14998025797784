<template>
  <v-container v-if="showItemInfos" class="customer-box">
    <v-row>
      <v-col cols="6">
        <div class="form-header text-heading-2 mb-5">
          物件/現場の基本情報
        </div>
      </v-col>
    </v-row>

    <v-container class="mt-4 px-0 py-0">
      <v-row no-gutters v-if="project.partner_0 || detailInfo.on_site_monitorings[project.on_site_monitoring] || project.partner_2 || project.partner_1" class="mb-4">
        <template v-if="project.partner_0">
          <v-col cols="2" class="customer-box-detail-headline mb-2"
            >メーカー
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{ project.partner_0 ? project.partner_0.name : '-' }}
          </v-col>
        </template>
        <template v-if="detailInfo.on_site_monitorings[project.on_site_monitoring]">
          <v-col cols="2" class="customer-box-detail-headline mb-2"
            >現場立ち合い
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">{{
            detailInfo.on_site_monitorings[project.on_site_monitoring]
              ? detailInfo.on_site_monitorings[project.on_site_monitoring]
              : '-'
          }}</v-col>
        </template>
        <template v-if="project.partner_2">
          <v-col cols="2" class="customer-box-detail-headline mb-2"
            >発注会社
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2"
            >{{ project.partner_2 ? project.partner_2.name : '-' }}
          </v-col>
        </template>
        <template v-if="project.partner_1">
          <v-col cols="2" class="customer-box-detail-headline mb-2"
            >元請会社
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2"
            >{{ project.partner_1 ? project.partner_1.name : '-' }}
          </v-col>
        </template>
      </v-row>
      <v-divider v-if="project.partner_0 || detailInfo.on_site_monitorings[project.on_site_monitoring] || project.partner_2 || project.partner_1"></v-divider>
      <v-row v-if="project.service_type_id == 0" no-gutters class="mt-4 mb-4">
        <template v-for="(info, index) in itemInfos">
          <template v-if="info.is_checkbox">
            <template v-if="project[info.keys].length > 0">
              <v-col
                cols="2"
                class="customer-box-detail-headline mb-2"
                :key="`item-info-headline-${index}`"
              >
                {{ info.title }}
              </v-col>
              <v-col
                cols="4"
                class="customer-box-detail-content mb-2"
                :key="`item-info-content-${index}`"
              >
                {{ showText(project[info.keys]) }}
              </v-col>
            </template>
          </template>
          <template v-else>
            <template v-if="detailInfo[info.keys][project[info.keys]]">
              <v-col
                cols="2"
                class="customer-box-detail-headline mb-2"
                :key="`item-info-headline-${index}`"
              >
                {{ info.title }}
              </v-col>
              <v-col
                cols="4"
                class="customer-box-detail-content mb-2"
                :key="`item-info-content-${index}`"
              >
                {{ detailInfo[info.keys][project[info.keys]] || '-' }}
                {{
                  info.has_extra && project[info.keys + '_text']
                    ? '(' + project[info.keys + '_text'] + ')'
                    : ''
                }}
              </v-col>
            </template>
          </template>
        </template>
      </v-row>
      <v-row v-if="project.service_type_id == 1" no-gutters class="mt-4 mb-4">
        <template v-if="detailInfo.elevator[project.elevator]">
          <v-col cols="2" class="customer-box-detail-headline mb-2">
            エレベータ
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{
              detailInfo.elevator[project.elevator]
                ? detailInfo.elevator[project.elevator]
                : '-'
            }}
          </v-col>
        </template>
        <template v-if="detailInfo.parking[project.parking]">
          <v-col cols="2" class="customer-box-detail-headline mb-2">
            駐車場
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{ detailInfo.parking[project.parking] }}
            {{
              project.parking_text && project.parking == 0
                ? '(' + project.parking_text + ')'
                : ''
            }}
          </v-col>
        </template>
        <template v-if="project.key && project.key.length">
          <v-col cols="2" class="customer-box-detail-headline mb-2">
            鍵
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{ showText(project.key) }}
          </v-col>
        </template>
      </v-row>
      <v-row v-if="project.service_type_id > 1" no-gutters class="mt-4 mb-4">
        <template v-if="detailInfo.parking[project.parking]">
          <v-col cols="2" class="customer-box-detail-headline mb-2">
            駐車場
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{ detailInfo.parking[project.parking] }}
            {{
              project.parking_text && project.parking == 0
                ? '(' + project.parking_text + ')'
                : ''
            }}
          </v-col>
        </template>
        <template v-if="project.key">
          <v-col cols="2" class="customer-box-detail-headline mb-2">
            鍵
          </v-col>
          <v-col cols="4" class="customer-box-detail-content mb-2">
            {{ showText(project.key) }}
          </v-col>
        </template>
      </v-row>
      <template v-if="project.general_guideline">
        <v-divider></v-divider>
        <v-row no-gutters align="baseline" class="mt-4">
          <v-col cols="2" class="customer-box-detail-headline mb-2"
            >現場に関するメモ
          </v-col>
          <v-col
            cols="10"
            class="customer-box-detail-content text-pre-wrap cust-line-height mb-2"
          >
            {{ project.general_guideline || '-' }}
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'PropertyInformation',
  props: {
    customer: {
      required: true
    },
    selectedService: {
      type: Number,
      required: true
    }
  },
  computed: {
    siteId() {
      return parseInt(this.$route.params?.site_id)
    },
    selectedSite() {
      return this.customer?.sites?.find(site => site.id === this.siteId)
    },
    project() {
      return this.selectedSite?.projects?.find(project => project.id === this.selectedService)
    },
    showItemInfos() {
      let will_show = false
      if (this.project.partner_0 || this.detailInfo.on_site_monitorings[this.project.on_site_monitoring] || this.project.partner_2 || this.project.partner_1 || this.project.general_guideline) {
        will_show = true
      } else if(this.project.service_type_id == 0) {
        this.itemInfos.forEach(element => {
          if (element.is_checkbox) {
            if (this.project[element.keys].length > 0) {
              will_show = true;
            }
          } else if (this.detailInfo[element.keys][this.project[element.keys]]) {
            will_show = true;
          }
        });
      } else if(this.project.service_type_id == 1) {
        if (this.detailInfo.elevator[this.project.elevator] || this.detailInfo.parking[this.project.parking] || this.project.key.length) {
          will_show = true;
        }
      } else if(this.project.service_type_id > 1) {
        if (this.detailInfo.parking[this.project.parking] || this.project.key || this.project.general_guideline) {
          will_show = true;
        }
      }
      return will_show
    }
  },
  data() {
    return {
      itemInfos: [
        { title: '駐車場', keys: 'parking', has_extra: true },
        { title: 'ブレーカー', keys: 'lightening_braker' },
        { title: '鍵', keys: 'key', is_checkbox: true },
        { title: '電源', keys: 'outlet' },
        { title: '新築/中古', keys: 'site_age' },
        { title: '異常', keys: 'electrical_issues', has_extra: true },
        { title: '入居者', keys: 'residents' },
        { title: 'シャッター', keys: 'shutter' },
        { title: '家具類', keys: 'furnitures' },
        { title: 'カーテン', keys: 'curtain' },
        { title: 'エアコン', keys: 'air_conditioner' }
      ],
      detailInfo: {
        elevator: ['あり', 'なし'],
        parking: ['敷地内', 'コインパーキング', '近隣'],
        lightening_braker: ['入', '切'],
        key: ['立ち合い', 'キーバンカー', 'その他対応'],
        electrical_issues: ['あり', 'なし'],
        site_age: ['新築', '中古'],
        outlet: ['本設', '仮設'],
        residents: ['あり', 'なし'],
        shutter: ['あり', 'なし'],
        furnitures: ['あり', 'なし'],
        curtain: ['あり', 'なし'],
        on_site_monitorings: ['あり', 'なし'],
        air_conditioner: ['あり', '使用', '不使用', '不明', 'なし']
      }
    }
  },
  methods: {
    showText(array) {
      if (array && Array.isArray(array)) {
        let text = array
          .map(c => {
            if (c) {
              if (c.extra_type == 'with_text') {
                return (
                  c.text + ' (' + (c.text_value ? c.text_value : c.value) + ')'
                )
              }
              return c.text ? c.text : c
            } else {
              return ''
            }
          })
          .join(', ')

        return text
      }
    }
  }
}
</script>

<style lang="scss" src="./PropertyInformation.scss" scoped></style>
